import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import { AsyncTypeahead, MenuItem } from 'react-bootstrap-typeahead';
import { Link, useLocation } from "react-router-dom";
import { deso_graphql, deso_api } from './graphql';
import ProfileCoin from '../components/ProfileCoin';
import ProfileStats, { fetchProfileStats } from '../components/ProfileStats';
import { FormatTextContent, nl2br, parseLinks } from './helpers';
import { DeSoIdentityContext } from 'react-deso-protocol';
import { Row, Card, Offcanvas, Nav, Tab, Form, Col } from 'react-bootstrap';
import { DirectTipButton, FollowUserButton, MessageUser, UserModeration } from './transactions';
import { getPostsStateless, getSinglePost } from 'deso-protocol';
import { currencyList } from './currency';
import { CustomisationUI, customisedExamples, setCustomCss } from '../components/CustomiseUX';
import { useNavigate } from 'react-router-dom';
import { getPosts, formatPost, PinnedPosts } from './posts';
import { Avatar } from './layouts';
import { useUserPreferences } from '../contexts/UserPreferences';
import { ContentBar } from '../components/ContentBar';

export const UserInput = ({ idName, searchTerm, setSearchTerm, onSelect, name, classes, handleChange, placeholder, search = false }) => {
  const { preferences } = useUserPreferences();
  const [isLoading, setIsLoading] = useState(false);
  //const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const typeaheadRef = useRef(null); 

  useEffect(() => {
    //console.log("[UserInput] path changed", location.pathname);
    //setInputValue('');
    //setSuggestions([]);
  }, [location]);

  const defaultSuggestion = {
    label: 'Search content or select profile',
    value: null,
    name: 'mentions',
    avatar: null,
    text: 'Search or select a profile',
    target: {
      label: 'Search for',
      value: null,
      name: 'mentions',
      avatar: null,
    },
  };

  const handleSearch = async (query) => {
    if(setSearchTerm) { setSearchTerm(query); }
    //setIsLoading(true);
    try {
      const apiEndpoint = 'get-profiles';
      const apiRequest = { UsernamePrefix: query, NumToFetch: 5 };
      //console.log("[UserInput] fetching profiles..., default:", defaultSuggestion);
      const data = await deso_api(apiEndpoint, apiRequest);
      const mappedSuggestions = data.ProfilesFound.map((profile) => ({
        label: profile.Username,
        value: profile.Username,
        name: name,
        avatar: profile.PublicKeyBase58Check ? preferences.nodeURI+`/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}` : null,
        target: {
          label: profile.Username,
          value: profile.Username,
          name: name,
          avatar: profile.PublicKeyBase58Check ? preferences.nodeURI+`/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}` : null,
          PublicKeyBase58Check: profile.PublicKeyBase58Check,
        },
      }));

      let suggestionsWithDefault;
      if(search) {
        suggestionsWithDefault = [{ text: 'Search content or select profile', label: query, search: true }, ...mappedSuggestions];
      } else {
        suggestionsWithDefault = mappedSuggestions;
      }
      //console.log("[UserInput] setting suggestions:", suggestionsWithDefault);
      setSuggestions(suggestionsWithDefault);
    } catch (error) {
      // console.error('Error fetching suggestions:', error);
      setSuggestions(defaultSuggestion);
    } finally {
      //setIsLoading(false);
    }
  };

  const onKeyDown = useCallback(
    (e) => {
      //console.log("[User Input] Keydown:", e);
      // Check whether the 'enter' key was pressed, and also make sure that
      // no menu items are highlighted.
      if (e.keyCode === 13 && activeIndex === 0) {
        // Execute the search.
        //console.log("[User Input] [ENTER] Keydown:", e);
        onSelect({ label: e.target.value, search: true });
      }
    },
    [activeIndex]
  );

  const handleSelectChange = (selected) => {
    if(setSearchTerm) { setSearchTerm(''); }
    typeaheadRef.current.clear();

    if (selected && selected.length > 0) {
      onSelect(selected[0]);
    } else {
      onSelect('');
    }
  };

  let elementId = idName ? idName : 'user-input-selector'
  //console.log("[UserInput] current suggestions:", suggestions);

  return (
    <AsyncTypeahead
      ref={typeaheadRef}
      id={idName}
      name={name}
      isLoading={isLoading}
      className={`menu-search-input ${classes}`}
      onSearch={handleSearch}
      onKeyDown={onKeyDown}
      minLength={4}
      inputValue={searchTerm}
      options={suggestions} // Pass suggestions as options
      onSelect={() => setSearchTerm('')}
      onChange={handleSelectChange}
      /*
      onChange={(selected) => {
        if (selected && selected.length > 0) {
          onSelect(selected[0]);
        } else {
          onSelect('');
        }
      }}
      */
      placeholder={placeholder}
      renderMenuItemChildren={(option) => (
        <>
          {option.avatar ? (
            <div className='p-0 m-0 d-flex align-items-center w-100 text-truncate'>
              <img src={option.avatar} alt="Avatar" className="deso_avatar me-2" />
              {option.label}
            </div>
          ) : (
            <div className='p-0 m-0 align-items-center w-100 text-truncate'>
              <i className='bi bi-search deso_avatar border-0 px-1 me-2 text-muted' style={{ fontSize: "1.15em" }}></i>
              {option.label}
            </div>
          )}
        </>
      )}      
    />
  );
};

export async function getProfileAPI(username = null, publicKey = null) {
    if (publicKey !== null) {
        api_request = { "PublicKeyBase58Check": publicKey, "NoErrorOnMissing": true, };
    } else if (username !== null) {
        // Replace '@' character with an empty string
        username = username.replace("@", "");
        var api_request = { "Username": username };
    }
    var api_endpoint = 'get-single-profile';
    var data = await deso_api(api_endpoint, api_request);
     //console.log("Profile:", data);
    if(!data) {
      var profile = {
        Username: "Anonymous Key",
        PublicKeyBase58Check: publicKey,
        isAnon: true,
      }
    } else {
      var profile = data["Profile"];
    }
    return profile;
}

export async function getFollowers(username = null, publicKey = null) {
    if (publicKey !== null) {
        api_request = { "PublicKeyBase58Check": publicKey };
    } else if (username !== null) {
        username = username.replace("@", "");
        var api_request = { "Username": username };
    }
    api_request.GetEntriesFollowingUsername = true;
    var api_endpoint = 'get-follows-stateless';
    var data = await deso_api(api_endpoint, api_request);
    // console.log("getFollowers:", data);
    return data;
}

export async function getFollowing(username = null, publicKey = null) {
    if (publicKey !== null) {
        api_request = { "PublicKeyBase58Check": publicKey };
    } else if (username !== null) {
        username = username.replace("@", "");
        var api_request = { "Username": username };
    }
    api_request.GetEntriesFollowingUsername = false;

    var api_endpoint = 'get-follows-stateless';
    var data = await deso_api(api_endpoint, api_request);
    // console.log("getFollowing:", data);
    return data;
}

export async function getCoinHolders(username = null, publicKey = null, IsDAOCoin = false) {
    if (publicKey !== null) {
        api_request = { "PublicKeyBase58Check": publicKey };
    } else if (username !== null) {
        username = username.replace("@", "");
        var api_request = { "Username": username };
    }
    api_request.FetchAll = true;
    api_request.IsDAOCoin = IsDAOCoin;
    var api_endpoint = 'get-hodlers-for-public-key';
    var data = await deso_api(api_endpoint, api_request);
    // console.log("getCoinHolders:", data);
    return data;
}
export async function getCoinHolding(username = null, publicKey = null, IsDAOCoin = false) {
    if (publicKey !== null) {
        api_request = { "PublicKeyBase58Check": publicKey };
    } else if (username !== null) {
        username = username.replace("@", "");
        var api_request = { "Username": username };
    }
    api_request.FetchHodlings = true;
    api_request.FetchAll = true;
    api_request.IsDAOCoin = IsDAOCoin;
    var api_endpoint = 'get-hodlers-for-public-key';
    var data = await deso_api(api_endpoint, api_request);
    // console.log("getCoinHolders:", data);
    return data;
}

export async function getProfile(publicKey){
    let profile = {};
    let variables = {
      "publicKey": publicKey
    }
    // console.log("getProfile("+publicKey+')');
    try {
        const data = await deso_graphql({
            query: `query FetchProfile($publicKey: String!) {
                      accountByPublicKey(publicKey: $publicKey) {
                        description
                        username
                        publicKey
                        profilePic
                        extraData
                        desoLockedNanos
                        coinPriceDesoNanos
                        creatorBasisPoints
                        profile {
                          account {
                            followers {
                              totalCount
                            }
                            following {
                              totalCount
                            }
                            creatorCoinBalances {
                              totalCount
                            }
                            creatorCoinBalancesAsCreator {
                              totalCount
                            }
                          }
                        }
                      }
                    }`,
              variables: variables,               
              operationName: "FetchProfile"
        });
        // console.log("getProfile graphQL data..."); // console.log(data);
        const profile = data.data.accountByPublicKey;
        // console.log("The Profile Fetched:", profile);
        if(profile) { return profile; }
    } catch (error) {
        // console.error("Error fetching graphQL data", error);
    }
    return null;
  }

  export const ProfileLight = ({ profile }) => {
    const { preferences } = useUserPreferences();
    //console.log("<ProfileLight : ",profile);
    let avatarSrc = preferences.nodeURI+`/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}`;
    if (profile.ExtraData && profile.ExtraData.LargeProfilePicURL && profile.ExtraData.LargeProfilePicURL !== '') {
      avatarSrc = profile.ExtraData.LargeProfilePicURL;
    }
    let header;
    if (profile.extraData && profile.extraData.FeaturedImageURL) {
      header = (
        <div className="rounded-3 m-0 p-0 ratio ratio-21x9" style={{ position: 'relative' }}>
          <img className={`feature rounded-top img-fluid p-0 m-0`} src={profile.extraData.FeaturedImageURL} />
        </div>
      );
    } else {
      header = (
        <div className="ratio ratio-21x9 bg-dark m-0 p-0 bg-gradient">
          <div className="m-0 p-0" style={{ width: '100%', height: 'auto', minHeight: '6em' }}> </div>
        </div>
      );
    }
    let avatarClass = '';
    if (profile && profile.lastTransaction) {
      const onlinePeriod = new Date(Date.now() - 30 * 60 * 1000);
      const idlePeriod = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000);
      const inactivePeriod = new Date(Date.now() - 45 * 24 * 60 * 60 * 1000);
      const lastTransactionDate = new Date(profile.lastTransaction);
  
      if (lastTransactionDate > onlinePeriod) {
        avatarClass = 'online';
      } else if (lastTransactionDate < idlePeriod) {
        avatarClass = 'muted';
      } else if (lastTransactionDate < lastTransactionDate) {
        avatarClass = 'inactive';
      }
    }
    // Render the card layout
    return (
      <Row className="justify-content-center rounded-2 m-0 p-0" style={{ width: "200px" }}>
        <Card className="h-100 position-relative rounded-2 bg-body m-0 p-0">
          <div className="ratio ratio-16x9">
            {profile.ExtraData && profile.ExtraData.FeaturedImageURL && (
              <Card.Img
                variant="top"
                src={profile.ExtraData.FeaturedImageURL}
                alt={`${profile.Username}'s Profile Banner`}
                className={`position-absolute cover-fit rounded-2 top-0 start-0 w-100 h-100`}
              />
            )}
            <div className="bg-body bg-opacity-50 position-absolute top-50 start-0 w-100 h-50">
              <Card.Body className="position-absolute rounded-2 top-50 start-50 translate-middle mt-3">
                <Card.Title className="fs-6 text-truncate">{profile.Username}</Card.Title>
              </Card.Body>
            </div>
          </div>
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            
              <Card.Img
                src={avatarSrc}
                alt={`${profile.Username}'s avatar`}
                className={`deso_avatar fs-1 text-center position-relative ${avatarClass}`}
              />
            
          </div>
        </Card>
      </Row>
    );
  };

export const ProfileHeader = ({ profile, imageSrc = null, mode = 'full', output, exchangeRates, tab }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser, alternateUsers, isLoading } = useContext(DeSoIdentityContext);
  const { preferences } = useUserPreferences();
  const [additionalData, setAdditionalData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [showCustomisation, setShowCustomisation] = useState(true);
  const [customisation, setCustomisation] = useState(null);
  const [customiseProfile, setCustomiseProfile] = useState(false);
  const [editProfileMode, setEditProfileMode] = useState(false);
  const [cssInjected, setCssInjected] = useState(null);
  const [activeTab, setActiveTab] = useState(tab && tab !== '' ? tab.toLowerCase() : 'home');
  const navigate = useNavigate();
 
  useEffect(() => {
    if (tab && tab !== '') {
      setActiveTab(tab.toLowerCase());
    }
  }, [tab]);

  //console.log("[profiles.js] ProfileCard props: ",currentUser,preferences,alternateUsers,tab,activeTab);

  useEffect(() => {
    // Retrieve user's customisations (if any)
      // Setup a dummy customisation... (really we'll fetch that from an API)
      /*const exampleUserCustomisationAssociation = {
          TransactorPublicKeyBase58Check: profile.PublicKeyBase58Check,
          TargetUserPublicKeyBase58Check: profile.PublicKeyBase58Check,
          AppPublicKeyBase58Check: null,
          AssociationType: 'test', // systemProfileIdentified
          AssociationValue: 'test', // arbituary value (maybe different pages or modules)
          ExtraData: {
              defaultStyles
          }
      };*/
    const exampleUserCustomisationAssociation = null;

    if(exampleUserCustomisationAssociation) {
      // If the user has saved a user association with their customisations
      setCustomisation(exampleUserCustomisationAssociation);
    } else if (customisedExamples[profile.Username]) {
      // Example customisation
      //console.log("[profiles.js] Load Customisation - example customisation for "+profile.Username,customisedExamples[profile.Username]);
      setCustomisation(customisedExamples[profile.Username]);
    } else {
      //console.log("[profiles.js] No customisation");
      setCustomisation(null);
    }
  }, [profile]);

  // Inject custom CSS into the document head
  let headerImageSrc;
      if (profile.extraData && profile.extraData.FeaturedImageURL) {
        // Featured Image in Profile
        headerImageSrc = profile.extraData.FeaturedImageURL;
      }

  useEffect(() => {
    if(customisation !== null) {
      if(showCustomisation === true) {
        setCustomCss(setCssInjected, cssInjected, profile, customisation, showCustomisation, setActiveTab);
      } else {
        setShowCustomisation(false);
        setCustomCss(setCssInjected, cssInjected, profile, customisation, showCustomisation, setActiveTab);
      }
    } else {
      setCustomCss(setCssInjected, cssInjected, profile, customisation, showCustomisation, setActiveTab);
    }
  }, [profile, customisation, showCustomisation]);
  

  //console.log("[PROFILE] Customisation:",customisation);

  const toggleExpand = () => {
      setExpanded(prevExpanded => !prevExpanded);
  };

  const headerClass = expanded ? 'expanded' : '';
  
  useEffect(() => {
    setAdditionalData(null);
  }, [profile]);

  useEffect(() => {
    const fetchBasicStatsData = async () => {
      try {
          const basicstats = await fetchProfileStats(null, profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchBasicStatsData return:", basicstats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...basicstats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
  };

    const fetchStatsData = async () => {
        try {
            const stats = await fetchProfileStats('detailed', profile.PublicKeyBase58Check);
            //console.log("[ProfileDashboard] fetchStatsData return:", stats);
            
            // Merging new stats with existing additionalData state
            setAdditionalData(prevData => ({
                ...prevData,
                ...stats,
            }));
        } catch (error) {
            console.error('[ProfileDashboard] Error fetching profile stats:', error);
        }
    };

    const fetchDiamondsData = async () => {
      try {
          const stats = await fetchProfileStats('diamonds', profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchDiamondsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };

    /*
    const fetchTxnsData = async () => {
      try {
          const stats = await fetchProfileStats('transactions', profile.PublicKeyBase58Check);
          console.log("[ProfileDashboard] fetchTxnsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };
    */
    const fetchPostsData = async () => {
      try {
          const stats = await fetchProfileStats('posts', profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchPostsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };



    const fetchHoldersData = async () => {
      try {
          const holders = await getCoinHolders(null, profile.PublicKeyBase58Check);
          const holding = await getCoinHolding(null, profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchHoldersData return:", holders);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              coinHolders: holders?.Hodlers,
              coinHolding: holding?.Hodlers
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
  };

    fetchBasicStatsData();
    fetchStatsData();
    fetchPostsData();
    //fetchTxnsData();
    fetchDiamondsData();
    fetchHoldersData();
  }, [profile.PublicKeyBase58Check]);

  useEffect(() => {
    const fetchBlockHeightData = async () => {
      try {
          const stats = await fetchProfileStats('blockHeight', profile.PublicKeyBase58Check, additionalData);
          //console.log("[ProfileDashboard] fetchTxnsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };
    if(!additionalData?.blockHeight && additionalData?.firstTransactionTimestamp) { 
      fetchBlockHeightData();
    }
  }, [additionalData]);

  //console.log("[profiles.js] the additional data: ", additionalData);

  if (!profile || typeof profile !== 'object') return null;

  if (profile && profile.username !== undefined && profile.Username === undefined) {
      // console.log("Detected a graphQL Profile");
  } else if (profile && profile.account === undefined) {
      // console.log("Detected a API Profile");
      profile.account = {};
      profile.account.followers = {};
      profile.account.followers = { "totalCount": profile["Followers"]?.totalCount || 0 };
      profile.description = profile["Description"];
      profile.publicKey = profile["PublicKeyBase58Check"];
      profile.extraData = profile["ExtraData"];
      profile.username = profile["Username"]
      profile.account.creatorCoinBalancesAsCreator = {};
      profile.account.creatorCoinBalancesAsCreator.totalCount = { "totalCount": profile["CoinEntry"]?.NumberOfHolders || 0 };
  } else {
    // console.log("No profile?");
  }

  let intro = nl2br(profile.description) || '';
  let topics = '';
  let subscribe = '';
  let header = '';

  if (!profile || !profile.account || !profile.account.followers || !profile.account.followers.totalCount || profile.account.followers.totalCount === "") {
    profile.account = {
      followers: {
        totalCount: 0
      },
      creatorCoinBalancesAsCreator: {
        totalCount: 0
      }
    }; // Set a default value for followers if it's not available
  }
  const taster = prepareTaster(profile);


  let subscriptions = null;
  // Check subscriptions
  if (subscriptions && subscriptions[profile.publicKey] && subscriptions[profile.publicKey]["Available_Hashtags"]) {
    topics = <h6 className="mb-0 pb-0">Subscription topic(s):</h6>;
    for (const [tag, description] of Object.entries(subscriptions[profile.publicKey]["Available_Hashtags"])) {
      topics += (
        <span className="badge bg-secondary rounded-pill mt-2 me-2 fw-normal">
          <i className="bi bi-bookmark"></i> #{tag} - {description}
        </span>
      );
    }
    subscribe = (
      <div className="input-group m-0 btn-group d-flex m-0" role="group">
        <a className="btn btn-success" href={`${window.location.pathname}?mode=subscribe&id=${profile.username}`} type="button">Subscribe</a>
        <a className="btn btn-info" type="button" href={`${window.location.pathname}?mode=u&id=${profile.username}`}>Feed</a>
      </div>
    );
  } else {
    topics = '';
    subscribe = (
      <div className="input-group m-0 btn-group d-flex my-2" role="group">
        <a className="btn btn-secondary disabled" href={`${window.location.pathname}?mode=subscribe&id=${profile.username}`} type="button">No subscription</a>
        <a className="btn btn-info" type="button" href={`${window.location.pathname}?mode=u&id=${profile.username}`}>Follow</a>
        <a className="btn btn-info" type="button" href={`${window.location.pathname}?mode=u&id=${profile.username}`}>Feed</a>
      </div>
    );
  }

  // Process extra data
  let avatar_src = preferences.nodeURI+`/api/v0/get-single-profile-picture/${profile.publicKey}`;
  if (profile.extraData && profile.extraData.NFTProfilePictureUrl && profile.extraData.NFTProfilePictureUrl !== '') {
    avatar_src = profile.extraData.NFTProfilePictureUrl
  } else if (profile.extraData && profile.extraData.LargeProfilePicURL && profile.extraData.LargeProfilePicURL !== '') {
    avatar_src = profile.extraData.LargeProfilePicURL;
  }
  
  const followers = profile.account.followers ? profile.account.followers.totalCount.toLocaleString() : null;
  const holders = profile.account.creatorCoinBalancesAsCreator.totalCount ? profile.account.creatorCoinBalancesAsCreator.totalCount.toLocaleString() : null;
  let userClass = '';
  if (profile.account.transactionStats && profile.account.transactionStats.latestTransactionTime) {
    const currentTime = new Date(); // Current time in the user's local timezone
    const latestTransactionTime = new Date(profile.account.transactionStats.latestTransactionTime); // Convert UTC timestamp to Date object
    if (latestTransactionTime > currentTime) {
      // If latestTransactionTime is in the future, do nothing
    } else {
      const timeDifference = currentTime - latestTransactionTime;
      const minutesDifference = timeDifference / (1000 * 60);
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      if (minutesDifference < 30) {
        userClass = ' online';
      } else if (daysDifference > 30) {
        userClass = ' longinactive';
      }
    }
  }

  let extraData = { "name": [], "links": [], "other": [] };
  let usernameOutput = profile.username;

  if (profile.extraData) {
    // console.log("we have extraData:", profile.extraData);

    if (profile.extraData.DisplayName && profile.extraData.DisplayName !== '') {
      usernameOutput = [
          profile.extraData.DisplayName.trim(),
          <br key="br" />, // Add key prop here
          <span className="fs-6 mt-0" key="username">@{profile.username}</span> // Add key prop here
      ];
    } else {
      usernameOutput = '@' + usernameOutput;
    }
    const ExtraDataFormat = {
      "DisplayName": { "label": 'Name', "section": "name" },
      "WebsiteURL": { "iconClass": 'bi bi-globe2', "url": null, "section": "links" },
      "DiscordURL": { "iconClass": 'bi bi-discord', "url": 'https://discordapp.com/users/[|]', "section": "links" },
      "githubURL": { "iconClass": 'bi bi-github', "url": null, "section": "links" },
      "FacebookURL": { "iconClass": 'bi bi-facebook', "url": null, "section": "links" },
      "LinkedInURL": { "iconClass": 'bi bi-linkedin', "url": null, "section": "links" },
      "InstagramURL": { "iconClass": 'bi bi-instagram', "url": null, "section": "links" },
      "TelegramURL": { "iconClass": 'bi bi-telegram', "url": null, "section": "links" },
      "TwitterURL": { "iconClass": 'bi bi-twitter-x', "url": null, "section": "links" },
    };
  
    for (const key in profile.extraData) {
        if (Object.hasOwnProperty.call(profile.extraData, key)) {
            const field = profile.extraData[key];
            const formatEntry = ExtraDataFormat[key];
            if (formatEntry && field !== "") {
                const { iconClass, url, section } = formatEntry;
                if (section === "links") {
                    let formattedField;
                    if (url) {
                        formattedField = (
                            <a className="text-info p-1" target="_blank" href={url.replace("[|]", field)}>
                                <i className={iconClass}></i>
                            </a>
                        );
                    } else {
                        formattedField = (
                            <a className="text-info p-1" target="_blank" href={field}>
                                <i className={iconClass}></i>
                            </a>
                        );
                    }
                    extraData.links.push(
                        <div className="px-3 fs-5" key={key}>
                            {formattedField}
                        </div>
                    );
                }
            } else {
                const label = key;
                extraData.other.push(<li className="list-inline-item" key={key}>{label}: {field}</li>);
            }
        }
    }
    // console.log(extraData);
    // console.log("Links:", extraData.links);
  }

  const navigateTo = (selectedTab) => {
    // Navigate to a new page with the selected username
    //console.log("[ProfileCard] navigateTo: ",selectedTab);
    setActiveTab(selectedTab);
    if(selectedTab !== '') {
      navigate(`/u/${profile.Username}/${selectedTab}`);
    } else {
      navigate(`/u/${profile.Username}`);
    }
  };

  if(cssInjected === null && customisation) {
    return;
  }

  return (
    <>
      {currentUser && currentUser.PublicKeyBase58Check === profile.PublicKeyBase58Check && customiseProfile ? (
        <CustomisationUI
          currentUser={currentUser}
          preferences={preferences}
          exchangeRates={exchangeRates}
          customisation={customisation}
          customiseProfile={customiseProfile}
          setCustomiseProfile={setCustomiseProfile}
          setCustomisation={setCustomisation}
        />
      ) : null}
      
      <div className='m-0 pb-3 profile-header-background'>
        <div className='container-fluid'>
          <div className="row">
            <div className="p-0 m-0" style={{ minHeight: '3em', overflow: 'hidden' }}>
            <div className={`m-0 p-0 ${profile.extraData && profile.extraData.FeaturedImageURL ? `profile-header` : `profile-header-noimage bg-gradient`}`}>              
              {profile.extraData && profile.extraData.FeaturedImageURL ? (
                <>
                  <img className={`feature reflect p-0 m-0 ${headerClass}`} src={profile.extraData.FeaturedImageURL} />
                  <img className={`feature p-0 m-0 ${headerClass}`} src={profile.extraData.FeaturedImageURL} />
                </>
              ) : (
                <>
                  <div className='feature reflect'></div>
                  <div className={`feature bg-opacity-50 m-1`}></div>
                </>
              )}
              <div className='position-absolute top-0 start-0 mt-5 pt-2 w-100 d-flex flex-row flex-nowrap justify-content-between'>
                {profile.extraData && profile.extraData.FeaturedImageURL && (
                  <span className="profileOverlay bg-body bg-opacity-75 d-none d-md-block btn m-2 p-1" onClick={toggleExpand}>
                    <i className="bi bi-arrows-angle-expand"></i>
                  </span>
                )}
                {currentUser && currentUser.PublicKeyBase58Check === profile.PublicKeyBase58Check ? (
                  <span className="profileOverlay bg-body bg-opacity-75 btn m-2 p-1" onClick={() => {setCustomiseProfile(prev => !prev);}}>
                    <i className={`bi bi-gear${customiseProfile ? `-fill` : ``}`}></i>
                  </span>
                ) : (
                  customisation && (
                    <span className="profileOverlay bg-body bg-opacity-75 btn m-2 p-1" onClick={() => setShowCustomisation(prev => !prev)}>
                      <i className={`bi bi-palette${showCustomisation ? `-fill` : ``}`}></i>
                    </span>
                  )
                )}
                {currentUser && currentUser.PublicKeyBase58Check === profile.PublicKeyBase58Check && (
                  <span className="profileOverlay bg-body bg-opacity-75 btn m-2 p-1" onClick={() => setEditProfileMode(prev => !prev)}>
                    <i className={`bi bi-pencil${editProfileMode ? `-fill` : ``}`}></i>
                  </span> 
                )}
              </div>
            </div>
            </div>
          </div>
        </div>
        {showCustomisation && customisation && customisation.ExtraData && customisation.ExtraData.palette &&customisation.ExtraData.palette.headerImageReflect ? (
              <div className='container-fluid' style={{ height: "0" }}>
                <div className="row">
                  <div className="p-0 m-0" style={{  }}>
                    <div className='effect-reflect-container'>
                      <img 
                      className={`feature effect-reflect p-0 m-0 ${headerClass}`} 
                      src={headerImageSrc} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            ): ( null )}
        <div className='container-fluid'>
          <div className='m-0'>
            <div className="w-100 profile p-0 m-0">
              <div className="col-12">
              <div className="row" style={{ marginTop: showCustomisation && customisation && customisation.ExtraData && customisation.ExtraData.palette && customisation.ExtraData.palette.headerImageReflect ? '-3em' : '-3em' }}>
                <div className="col-12 text-center d-flex justify-content-center">
                  <Avatar size={10} type="avatar" publicKey={profile.PublicKeyBase58Check} />
                </div>
              </div>
              <div className="row mx-0" style={{ height: '6em', marginTop: '-5.8em' }}>
                <div className="col-6 text-start small p-0 profileOverlay d-flex flex-row flex-nowrap justify-content-start align-items-start">
                  <DirectTipButton SenderPublicKeyBase58Check={currentUser && currentUser.PublicKeyBase58Check ? currentUser.PublicKeyBase58Check : null} Receiver={profile} source="Profile" size='sm' />
                  <MessageUser label={true} ReceiverPublicKeyBase58Check={profile.PublicKeyBase58Check} />
                </div>
                <div className="col-6 justify-content-end small p-0 profileOverlay d-flex flex-row flex-nowrap justify-content-end align-items-start">
                  <FollowUserButton label={true} currentUser={currentUser} ReceiverPublicKeyBase58Check={profile.PublicKeyBase58Check} className='mx-1 px-2' size='sm' />
                  <UserModeration currentUser={currentUser} target={profile} label={true} className='mx-1 px-2 btn-secondary' size='sm' />
                </div>
                <div className="col-12 ps-0 text-start small p-0 h-100">
                  <div className="d-flex flex-row flex-nowrap profileOverlay">
                    {additionalData && additionalData.coinHolders && additionalData.coinHolding && (
                      <div className='overlay text-start mt-2 p-1 ps-2'>
                        <i className="bi bi-coin"></i><br/>
                        <span className="fw-bold">{additionalData?.coinHolders?.length.toLocaleString()}</span> <span className='small text-muted'>holders</span><br/>
                        <span className="fw-bold">{additionalData?.coinHolding?.length.toLocaleString()}</span> <span className='small text-muted'>holding</span>
                      </div>
                    )}
                    <div className='flex-grow-1 h-100'></div>
                    {additionalData && additionalData.followers && additionalData.following && (
                      <div className='overlay text-end mt-2 p-1 pe-2'>
                        <i className="bi bi-people-fill"></i><br/>
                        <span className='small text-muted'>followers</span>&nbsp;
                        <span className='fw-bold'>
                            {additionalData?.followers?.totalCount?.toLocaleString()}<br/>
                        </span>
                        <span className='small text-muted'>following</span>&nbsp;
                        <span className='fw-bold'>
                            {additionalData?.following?.totalCount?.toLocaleString()}
                        </span>
                      </div>
                  )}
                  </div>
                </div>
              </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <h4 className="mx-auto text-center">{usernameOutput}</h4>
                    <div id="publicKey" data-publickey={profile.publicKey}></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 p-0 m-0 d-flex flex-nowrap justify-content-evenly text-center">
                  {extraData && extraData.links && extraData.links.length > 0 && (
                        extraData.links.map((link, index) => (
                            <div key={index} className="p-2 fs-5">
                                {link.props.children}
                            </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
              {mode === "full" && (
              <div className="col-12 pb-3 text-center small word-break"><FormatTextContent text={taster}/></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    );
  };

export const ProfileWrapper = ({ profile, imageSrc = null, mode = 'full', output, preferences, exchangeRates, tab }) => {
    return (
        <div className='custom'>
          <ProfileHeader profile={profile} imageSrc={imageSrc} mode={mode} tab={tab}/>
          {output && (
            <div className='profile-page-content'>{output}</div>
          )}
        </div>
      );
    };

export const ProfileCard = ({ profile, mode = "full", currentUser, alternateUsers, accessGroups, preferences, exchangeRates, tab, feed }) => {
  //console.log("ProfileCard:", profile);
  const [loading, setLoading] = useState(true);
  const [additionalData, setAdditionalData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [showCustomisation, setShowCustomisation] = useState(true);
  const [customisation, setCustomisation] = useState(null);
  const [customiseProfile, setCustomiseProfile] = useState(false);
  const [editProfileMode, setEditProfileMode] = useState(false);
  const [cssInjected, setCssInjected] = useState(null);
  const [activeTab, setActiveTab] = useState(tab && tab !== '' ? tab.toLowerCase() : 'home');
  const navigate = useNavigate();
 
  useEffect(() => {
    if (tab && tab !== '') {
      setActiveTab(tab.toLowerCase());
    }
  }, [tab]);

  //console.log("[profiles.js] ProfileCard props: ",currentUser,preferences,alternateUsers,tab,activeTab);

  useEffect(() => {
    // Retrieve user's customisations (if any)
      // Setup a dummy customisation... (really we'll fetch that from an API)
      /*const exampleUserCustomisationAssociation = {
          TransactorPublicKeyBase58Check: profile.PublicKeyBase58Check,
          TargetUserPublicKeyBase58Check: profile.PublicKeyBase58Check,
          AppPublicKeyBase58Check: null,
          AssociationType: 'test', // systemProfileIdentified
          AssociationValue: 'test', // arbituary value (maybe different pages or modules)
          ExtraData: {
              defaultStyles
          }
      };*/
    const exampleUserCustomisationAssociation = null;

    if(exampleUserCustomisationAssociation) {
      // If the user has saved a user association with their customisations
      setCustomisation(exampleUserCustomisationAssociation);
    } else if (customisedExamples[profile.Username]) {
      // Example customisation
      //console.log("[profiles.js] Load Customisation - example customisation for "+profile.Username,customisedExamples[profile.Username]);
      setCustomisation(customisedExamples[profile.Username]);
    } else {
      //console.log("[profiles.js] No customisation");
      setCustomisation(null);
    }
  }, [profile]);

  // Inject custom CSS into the document head
  let headerImageSrc;
      if (profile.extraData && profile.extraData.FeaturedImageURL) {
        // Featured Image in Profile
        headerImageSrc = profile.extraData.FeaturedImageURL;
      }

  useEffect(() => {
    if(customisation !== null) {
      if(showCustomisation === true) {
        setCustomCss(setCssInjected, cssInjected, profile, customisation, showCustomisation, setActiveTab);
      } else {
        setShowCustomisation(false);
        setCustomCss(setCssInjected, cssInjected, profile, customisation, showCustomisation, setActiveTab);
      }
    } else {
      setCustomCss(setCssInjected, cssInjected, profile, customisation, showCustomisation, setActiveTab);
    }
  }, [profile, customisation, showCustomisation]);
  

  //console.log("[PROFILE] Customisation:",customisation);

  const toggleExpand = () => {
      setExpanded(prevExpanded => !prevExpanded);
  };

  const headerClass = expanded ? 'expanded' : '';
  
  useEffect(() => {
    setAdditionalData(null);
  }, [profile]);

  useEffect(() => {
    const fetchBasicStatsData = async () => {
      try {
          const basicstats = await fetchProfileStats(null, profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchBasicStatsData return:", basicstats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...basicstats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };

    const fetchStatsData = async () => {
        try {
            const stats = await fetchProfileStats('detailed', profile.PublicKeyBase58Check);
            //console.log("[ProfileDashboard] fetchStatsData return:", stats);
            
            // Merging new stats with existing additionalData state
            setAdditionalData(prevData => ({
                ...prevData,
                ...stats,
            }));
        } catch (error) {
            console.error('[ProfileDashboard] Error fetching profile stats:', error);
        }
    };

    const fetchDiamondsData = async () => {
      try {
          const stats = await fetchProfileStats('diamonds', profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchDiamondsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };

    /*
    const fetchTxnsData = async () => {
      try {
          const stats = await fetchProfileStats('transactions', profile.PublicKeyBase58Check);
          console.log("[ProfileDashboard] fetchTxnsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };
    */
    const fetchPostsData = async () => {
      try {
          const stats = await fetchProfileStats('posts', profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchPostsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };



    const fetchHoldersData = async () => {
      try {
          const holders = await getCoinHolders(null, profile.PublicKeyBase58Check);
          const holding = await getCoinHolding(null, profile.PublicKeyBase58Check);
          //console.log("[ProfileDashboard] fetchHoldersData return:", holders);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              coinHolders: holders?.Hodlers,
              coinHolding: holding?.Hodlers
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };

    const fetchTokenData = async () => {
      try {
          const holders = await getCoinHolders(null, profile.PublicKeyBase58Check, true);
          const holding = await getCoinHolding(null, profile.PublicKeyBase58Check, true);

          // order each by holders/holding .Hodlers.BalanceNanos descending
          holders.Hodlers.sort((a, b) => (a.BalanceNanos > b.BalanceNanos) ? -1 : 1);
          holding.Hodlers.sort((a, b) => (a.BalanceNanos > b.BalanceNanos) ? -1 : 1);
          //console.log("[ProfileDashboard] fetchHoldersData return:", holders);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              tokenHolders: holders?.Hodlers,
              tokenHolding: holding?.Hodlers
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };

    fetchBasicStatsData();
    fetchStatsData();
    fetchPostsData();
    //fetchTxnsData();
    fetchDiamondsData();
    fetchHoldersData();
    fetchTokenData();
  }, [profile]);

  useEffect(() => {
    const fetchBlockHeightData = async () => {
      try {
          const stats = await fetchProfileStats('blockHeight', profile.PublicKeyBase58Check, additionalData);
          //console.log("[ProfileDashboard] fetchTxnsData return:", stats);
          
          // Merging new stats with existing additionalData state
          setAdditionalData(prevData => ({
              ...prevData,
              ...stats,
          }));
      } catch (error) {
          console.error('[ProfileDashboard] Error fetching profile stats:', error);
      }
    };
    if(!additionalData?.blockHeight && additionalData?.firstTransactionTimestamp) { 
      fetchBlockHeightData();
    }
  }, [additionalData]);

  //console.log("[profiles.js] the additional data: ", additionalData);

  if (!profile || typeof profile !== 'object') return null;

  const navigateTo = (selectedTab) => {
    // Navigate to a new page with the selected username
    //console.log("[ProfileCard] navigateTo: ",selectedTab);
    setActiveTab(selectedTab);
    if(selectedTab !== '') {
      navigate(`/u/${profile.Username}/${selectedTab}`);
    } else {
      navigate(`/u/${profile.Username}`);
    }
  };

  if(cssInjected === null && customisation) {
    return;
  }
  let intro = nl2br(parseLinks(profile.description)) || '';
  const thisReturn = (
      <>
        {mode === "full" && (
          <ContentBar search={false} profile={profile} rootLocation={`/u/${profile.Username}`} setActiveTab={setActiveTab} activeTab={activeTab} customisation={customisation} />
        )}
        <div className='container'>
          {mode === "full" && (activeTab === 'home' || activeTab === 'stats') && (
            <div className='container d-flex flex-column flex-fill'>
              <div className="tab-content d-flex flex-column flex-fill" id="profileTabs">
                {activeTab === 'home' && (
                  <div className={`tab-pane fade${activeTab === 'home' ? ` show active` : ``}`} id="profileProfile" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                    <div className="profileContent word-break">
                      {customisation && customisation.ExtraData && customisation.ExtraData.modules && Object.keys(customisation.ExtraData.modules).length > 0 ? (
                        Object.values(customisation.ExtraData.modules).map((module, index) => (
                          <ProfileModule profile={profile} key={index} module={module} intro={intro} currentUser={currentUser} />
                        ))
                      ) : (
                        <div className='py-3'><FormatTextContent text={profile.description}/></div>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === 'stats' && (
                  <div className={`tab-pane d-flex flex-column flex-fill fade${activeTab === 'stats' ? ` show active` : ``}`} id="profileStats" role="tabpanel" aria-labelledby="stats-tab" tabIndex={3}>
                    <ProfileStats profile={profile} preferences={preferences} exchangeRates={exchangeRates} additionalData={additionalData} setAdditionalData={setAdditionalData} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </>
  );
  
  return <ProfileWrapper profile={profile} imageSrc={null} mode={mode} output={thisReturn} preferences={preferences} exchangeRates={exchangeRates} tab={tab} />;
};

export const ProfileModule = ({ key, profile, module, intro, currentUser }) => {

  //console.log("[Profiles] ProfileModule module:",module);

  switch (module.type) {
    case "embed":
      if (module.embedUrl.includes("spotify.com")) {
        // Extract Spotify track ID from the embed URL
        const trackId = module.embedUrl.split("/").pop();
        // Construct the Spotify embed URL
        const embedUrl = `https://open.spotify.com/embed/track/${trackId}`;
        // Render the Spotify embed iframe
        return (
          <div className='m-0 p-0 pb-3'>
            <iframe
              style={{ borderRadius: "12px", margin: "0" }}
              src={embedUrl}
              className='card-effect'
              width="100%"
              height="152"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              title="Spotify Embed"
              loading="lazy"
            />
          </div>
        );
      } else if (module.embedUrl.includes("https://onedrive.live.com")) {
        // Render the Spotify embed iframe
        return (
          <div className='m-0 p-0 pb-3'>
            <iframe
              style={{ borderRadius: "12px", margin: "0" }}
              src={module.embedUrl}
              className='card-effect'
              width="100%"
              height="152"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              title="OneDrive Embed"
              loading="lazy"
            />
          </div>
        );
      } else if (module.embedUrl.includes("https://mousai.stream")) {
        // Render other embed URLs
        return (
          <div className='m-0 p-0 pb-3'>
            <iframe
              width="100%"
              className='card-effect'
              style={{ margin: "0" }}
              height="165"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src={module.embedUrl}
            />
          </div>
        );
      } else {
        // Render default embed iframe
        return (
          <div className='m-0 p-0 pb-3'>
            <iframe
              id="embed-iframe"
              className='card-effect'
              frameBorder="0"
              allow="picture-in-picture; clipboard-write; encrypted-media; gyroscope; accelerometer; encrypted-media;"
              allowFullScreen=""
              height="380"
              style={{ width: "100%", background: "none", margin: "0" }}
              src={module.embedUrl}
              title="Embed"
              loading="lazy"
            />
          </div>
        );
      }
    case "pinned":
        return ( <PinnedPosts profile={profile} currentUser={currentUser} /> );
      break;
    case "carousel":
      return (
        <div id={`carousel-${module.type}`} className="mb-5 carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner card-effect">
            {Object.entries(module.images).map(([imageUrl, detail], index) => (
              <Link to={`/posts/${detail.PostHashHex}`}>
                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <img src={imageUrl} className="d-block w-100 imageAspect" alt={`Slide ${index}`} />
                </div>
              </Link>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-${module.type}`} data-bs-slide="prev">
            <span className="carousel-control-prev-icon text-info" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target={`#carousel-${module.type}`} data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      );
    case "text":
        return <div className='my-2'>{module.content}</div>;
    case "profile":
        return <div className='word-break'><FormatTextContent text={profile.description}/></div>;
      break;
    default:
      return null;
  }
};

const prepareTaster = (profile) => {
  //console.log("ProfileCard updated:", profile);
  const MAX_TASTER_LENGTH = 100; // Define a max length for a reasonable "taster"

  // Process description into a taster
  const description = profile.description || ''; // Default to an empty string if description is undefined or null

  // 1. Split by paragraphs (\n\n)
  const descriptionParts = description.split(/\n\n/); // Safely split by double newlines
  let tmpParts = [];

  // 2. Use the first part if it exists
  if (descriptionParts.length > 0 && descriptionParts[0]) {
      // Split the first paragraph by single newlines (\n), limit to the first 2 lines
      tmpParts = descriptionParts[0].split(/\n/).slice(0, 2);
  }

  // Join the taster parts
  let tmp = tmpParts.join('\n').trim();

  // 3. If the tmp is still too long, try to shorten by sentence
  if (tmp.length > MAX_TASTER_LENGTH) {
      // Split by sentence boundaries, limit to the first sentence
      tmp = tmp.split(/(?<=\.\s)|(?<=!\s)|(?<=\?\s)/)[0];
  }

  // 4. If the sentence approach didn’t work, truncate and append "..."
  if (tmp.length > MAX_TASTER_LENGTH) {
      tmp = tmp.slice(0, MAX_TASTER_LENGTH).trim() + "...";
  }

  // Convert newlines to <br> for display
  //tmp = nl2br(tmp);
  return tmp;
}