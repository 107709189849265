import React, { useRef, useEffect, useState, useContext } from 'react';
import { Carousel, OverlayTrigger, Row, Tooltip, Modal, Container, Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { deso_graphql, deso_api } from './graphql';
import Quill from 'quill';
import ReactMarkdown from 'react-markdown';
import { parseLinks, friendlyFormatDate, nl2br, FormatTextContent } from './helpers';
import { ProfileCard, ProfileLight, getProfile, getProfileAPI } from './profiles';
import QuickReplyForm from '../components/quickReply';
import { checkPartyAccessGroups, getPostAssociations, getPostsHashHexList, getSinglePost, getUserAssociations, identity, submitPost } from 'deso-protocol';
//import 'quill/dist/quill.snow.css';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import Turndown from 'turndown';
import { DeSoIdentityContext } from "react-deso-protocol";
import { useUserPreferences } from "../contexts/UserPreferences";
import { Loader } from './helpers';
import { Avatar, UsernameComponent } from './layouts';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { localFees, Bookmark, DirectTipButton, SendDiamondsButton, SendReactionButton, SendRepostButton, updateBookmark } from './transactions.js';
import LivePeerVideo from '../components/VideoLivePeer.jsx';
import { SubscriptionButton, checkSubscriptions, subscriptionOptions } from './subscriptions.js';
import { ReplyForm } from '../components/modalReply.jsx';
import { PostMedia, TweetEmbed } from './postAttachments.js';
import { PostComments } from './layouts_comments.js';
import { PostPlaceholder } from './layouts_posts.js';
import { AccessGroupsContext } from '../contexts/AccessGroups.js';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Define your async function
const fetchDataFromAPI = async () => {
    try {
      // Define your API endpoint
      const apiEndpoint = "get-app-state";
      
      // Define your API request data
      const apiRequest = {
        publicKeyBase58Check: "BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD" // Assuming $PublicKeyBase58Check is already defined
      };
      
      const data = await deso_api(apiEndpoint, apiRequest);
      
      // Extract the "Nodes" from the response data
      const nodes = data.Nodes;
      
      // Output the "Nodes" to the console
      //console.log("Nodes:", nodes);
    } catch (error) {
      // Handle any errors that occur during the process
      //console.error("Error fetching data:", error);
    }
  };
  
const postfields = `
  timestamp
  body
  postHash
  isFrozen
  isHidden
  isNft
  isPinned
  repostedPostHash
  isQuotedRepost
  parentPostHash
  posterPublicKey
  poster {
    username
    profilePic
    publicKey
    extraData
    description
    transactionStats {
      latestTransactionTimestamp
    }
  }
  imageUrls
  videoUrls
  extraData
  likes {
    totalCount
  }
  diamonds {
    totalCount
  }
  diamondsTotalValue {
    nodes {
      totalDiamondValueNanos
    }
  }
  reposts {
    totalCount
  }
  numNftCopiesForSale
  numNftCopies
  numNftCopiesBurned
`;

function abortablePromise(promise, signal) {
    if (!signal) return promise;

    return Promise.race([
        promise,
        new Promise((_, reject) => {
            signal.addEventListener(
                "abort",
                () => reject(new DOMException("Aborted", "AbortError")),
                { once: true } // Ensure the listener is removed after triggering
            );
        }),
    ]);
}

export async function getPosts(variables = {}, currentUser = null, preferences = null, thread = null, signal) {
    try {
        if (!variables.condition || !variables.condition.isHidden) {
            if (!variables.condition) {
                variables.condition = {};
            }
            variables.condition.isHidden = false;
        }

        // Wrap the deso_graphql call
        const data = await abortablePromise(
            deso_graphql({
                query: `query SearchPosts($condition: PostCondition, $filter: PostFilter, $first: Int, $orderBy: [PostsOrderBy!], $after: Cursor) {
                    posts(condition: $condition, filter: $filter, first: $first, orderBy: $orderBy, after: $after) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            endCursor
                            startCursor
                        }
                        nodes {
                            timestamp
                            postHash
                            posterPublicKey
                        }
                    }
                }`,
                variables: variables,
                operationName: "SearchPosts",
            }),
            signal
        );

        const returnData = data.data.posts;
        const postQueries = [];

        //console.log("[posts.js] fetching with thread = ",thread);
        if (thread) {
            // Wrap getSinglePost calls
            returnData.nodes.forEach((post) => {
                const query = abortablePromise(
                    getSinglePost({
                        PostHashHex: post.postHash,
                        ReaderPublicKeyBase58Check: currentUser ? currentUser.PublicKeyBase58Check : null,
                        FetchParents: true,
                    }),
                    signal
                );
                postQueries.push(query);
            });

            const postResults = await Promise.allSettled(postQueries);
            const validPosts = postResults
                .filter((result) => result.status === "fulfilled" && result.value?.PostFound)
                .map((result) => result.value);

            console.log("[posts.js] THREADED ValidPosts:",validPosts);
            return { ...returnData, nodes: validPosts };
        } else {
            const postHashes = returnData.nodes.map((post) => post.postHash);

            // Wrap getPostsHashHexList call
            const response = await abortablePromise(
                getPostsHashHexList({
                    PostsHashHexList: postHashes,
                    ReaderPublicKeyBase58Check: currentUser ? currentUser.PublicKeyBase58Check : null,
                    FetchParents: true,
                }),
                signal
            );

            if (response?.PostsFound) {
                const validPosts = response.PostsFound.map((post) => ({ PostFound: post }));
                return { ...returnData, nodes: validPosts };
            } else {
                console.error("[Search getPosts] Error fetching posts:", response?.Error || "Unknown error");
                return { ...returnData, nodes: [] };
            }
        }
    } catch (error) {
        if (error.name === "AbortError") {
            console.log("[Search getPosts] Request aborted");
            return null; // Clean exit on abort
        }
        console.error("[Search getPosts] Error fetching graphQL data", error);
        return null;
    }
}


/* original getPosts
export async function getPosts(variables = {}, currentUser = null, preferences, thread = null, signal) {
    try {
        //console.log("[getPosts]");
        if (!variables.condition || !variables.condition.isHidden) {
            if (!variables.condition) {
                variables.condition = {};
            }
            variables.condition.isHidden = false;
        }

        //console.log("[getPosts] query with:", variables);
        
        const data = await deso_graphql({
            query: `query SearchPosts($condition: PostCondition, $filter: PostFilter, $first: Int, $orderBy: [PostsOrderBy!], $after: Cursor) {
                posts(condition: $condition, filter: $filter, first: $first, orderBy: $orderBy, after: $after) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        endCursor
                        startCursor
                    }
                    nodes {
                        timestamp
                        postHash
                        posterPublicKey
                    }
                    
                }
            }`,
            variables: variables,
            operationName: "SearchPosts"
        });

        
        //Removed from nodes:
        //diamondsTotalValue {
        //                    nodes {
        //                        totalDiamondValueNanos
        //                    }
        //                }
        
        const returnData = data.data.posts;

        // Define an array to store the results of the queries
        const postQueries = [];

        if(thread) {
            // Iterate over each post in returnData and create a query for each post
            returnData.nodes.forEach((post) => {
                const query = getSinglePost({
                    PostHashHex: post.postHash,
                    ReaderPublicKeyBase58Check: currentUser ? currentUser.PublicKeyBase58Check : null,
                    FetchParents: true,
                });
                postQueries.push(query);
            });
            // Execute all queries asynchronously
            const postResults = await Promise.allSettled(postQueries);
            //console.log("[Search getPosts] postResults:", postResults);

            const validPosts = [];
            const errors = [];
            postResults.forEach((result, index) => {
                if (result.status === "fulfilled" && result.value && result.value["PostFound"]) {
                    validPosts.push(result.value);
                } else if (result.status === "rejected") {
                    errors.push(result.reason);
                }
            });
            console.log("[posts.js] The Posts Fetched:", validPosts);
            //console.error("[Search getPosts] Errors fetching posts:", errors);
            return { ...returnData, nodes: validPosts };
        } else {
            const postHashes = returnData.nodes.map((post) => post.postHash);

            // Use the getPostsHashHexList API to fetch posts
            const response = await getPostsHashHexList({
                PostsHashHexList: postHashes,
                ReaderPublicKeyBase58Check: currentUser ? currentUser.PublicKeyBase58Check : null,
                FetchParents: true,
            });
    
            // Check if the response contains the posts and handle errors
            console.log("[posts.js] response: ",response);
            if (response && response.PostsFound) {
                const validPosts = response.PostsFound.map(post => ({ PostFound: post }));
                return { ...returnData, nodes: validPosts };
            } else {
                console.error("[Search getPosts] Error fetching posts:", response?.Error || "Unknown error");
                return { ...returnData, nodes: [] };
            }
        }
        
    } catch (error) {
        console.error("[Search getPosts] Error fetching graphQL data", error);
        return null;
    }
}
*/

  /********************************************************
 * Common Output Generation
 */
export async function formatPost(post, currentUser, level = 0, type = 'post', view = 'list', accessGroups = null, preferences, thread, preview, alternateUsers, isExpanded) {

    // Moderation
    let userModerationFlags = null;
    let postModerationFlags = null;
    let moderationOutcome = true; // Default is to show the post

    // Load default moderation settings
    const appDefaultModerationSettings = {};

    // Merge user moderation settings with defaults
    const mergedModerationSettings = { ...appDefaultModerationSettings, ...preferences?.moderationSettings };

    // Identify flags of interest (set to false or null)
    const moderationFlagsOfInterest = Object.keys(mergedModerationSettings).filter(
        (flag) => mergedModerationSettings[flag] === false || mergedModerationSettings[flag] === null
    );

    // If there are flags of interest, proceed with API calls
    if (moderationFlagsOfInterest.length > 0) {
        // Fetch user associations first, only if PosterPublicKeyBase58Check exists
        if (post.PosterPublicKeyBase58Check) {
            try {
                const userAssociationRequest = {
                    TargetUserPublicKeyBase58Check: post.PosterPublicKeyBase58Check,
                    AssociationType: "FLAG",
                    AssociationValues: moderationFlagsOfInterest
                };
                userModerationFlags = await getUserAssociations(userAssociationRequest);
                //console.log("MODERATION - userModerationFlags:", userAssociationRequest, userModerationFlags);

                // Evaluate user-level moderation flags
                if (userModerationFlags?.Associations?.length > 0) {
                    userModerationFlags.Associations.forEach((association) => {
                        const userFlagSetting = mergedModerationSettings[association.AssociationValue];
                        if (userFlagSetting === false) {
                            moderationOutcome = false; // Block post based on user moderation setting
                        } else if (userFlagSetting === null && moderationOutcome !== false) {
                            moderationOutcome = null; // Mask post unless it's already marked for blocking
                        }
                    });

                    // If the post is already marked for blocking, skip further checks
                    if (moderationOutcome === false) {
                        //console.log("MODERATION - Blocking post based on user moderation flags.");
                        return null; // Block post, no need to check post associations
                    }
                }
            } catch (error) {
                console.error("Error fetching user associations:", error);
            }
        }

        
        // Only fetch post associations if no block occurred at the user level
        if (moderationOutcome !== false && post.PostHashHex) {
            try {
                const postAssociationRequest = {
                    PostHashHex: post.PostHashHex,
                    AssociationType: "FLAG",
                    AssociationValues: moderationFlagsOfInterest
                };
                postModerationFlags = await getPostAssociations(postAssociationRequest);
                //console.log("MODERATION - postModerationFlags:", postAssociationRequest, postModerationFlags);

                // Evaluate post-level moderation flags
                if (postModerationFlags?.Associations?.length > 0) {
                    postModerationFlags.Associations.forEach((association) => {
                        const postFlagSetting = mergedModerationSettings[association.AssociationValue];
                        if (postFlagSetting === false) {
                            moderationOutcome = false; // Block post based on post moderation setting
                        } else if (postFlagSetting === null && moderationOutcome !== false) {
                            moderationOutcome = null; // Mask post unless it's already marked for blocking
                        }
                    });
                }
            } catch (error) {
                console.error("Error fetching post associations:", error);
            }
        }
            
    }

    // Take action based on the moderation outcome
    if (moderationOutcome === false) {
        //console.log("MODERATION - Blocking post");
        return null; // Block the post
    } else if (moderationOutcome === null) {
        //console.log("MODERATION - Masking post");
        // Handle masking logic (e.g., blur post content, show warning)
    } else {
        //console.log("MODERATION - Showing post");
        // Handle showing post normally
    }


    //console.log("formatPost",post);




    if(!post || !post.PosterPublicKeyBase58Check) { return; }

    if(view === 'grid' || view === 'media') {
        if(type!==`media`) {
            type = 'media';
        }
    }
    //console.log("[posts.js] formatPost START Formatting post: level, type, thread, post: ",level,type,thread,post);
    //console.log("[formatPost] accessGroups:", accessGroups);

    if (level === null || level === '') {
        level = 0;
    }

    const handleShare = async ({ title, taster, url }) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: title,
                    text: taster,
                    url: url
                });
            } else {
                alert('Web Share API not supported in your browser.');
            }
        } catch (error) {
            // console.error('Error sharing:', error.message);
        }
    };

    const formattedTimestamp = new Date(post.TimestampNanos/1000000).toLocaleString();
    const timeSince = friendlyFormatDate(post.TimestampNanos/1000000);
    
    let usernameOutput = <UsernameComponent profile={post.ProfileEntryResponse} />;
    
    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
    };

    let postAttachments = [];
    let musicAttachments = [];
    let postQuoted = '';
    let userAvatar = (
        <Avatar suppliedProfile={post.ProfileEntryResponse} publicKey={post.PosterPublicKeyBase58Check} />
        );
    let userText = (
        <Avatar type="username" suppliedProfile={post.ProfileEntryResponse} publicKey={post.PosterPublicKeyBase58Check} />
        );

    // isQuotedRepost repostedPostHash parentPostHash
    let postType = "Post";
    let postTypeInline = <span className="fw-bolder">posted</span>;
    let repost = null;

    if (post.RepostedPostEntryResponse && ( post.Body || (post.ImageURLs && post.ImageURLs.length > 0) || (post.VideoURLs && post.VideoURLs.length > 0) || post.PostExtraData.EmbedVideoURL)) {
        postType = "Quote Repost";
        postTypeInline = <span className="fw-bolder">quoting</span>;
        postQuoted = await formatPost(post.RepostedPostEntryResponse, currentUser, 0, "quote", accessGroups, preferences);
        if (post.RepostedPostEntryResponse.ProfileEntryResponse && post.RepostedPostEntryResponse.ProfileEntryResponse.Username) { postTypeInline = <> {postTypeInline} <span className="fw-bolder">{post.RepostedPostEntryResponse.ProfileEntryResponse.Username}</span></>; }
    } else if (post.RepostedPostEntryResponse) {
        postType = "Repost";
        postTypeInline = <span className="fw-bolder">reposting</span>;
        repost = await formatPost(post.RepostedPostEntryResponse, currentUser, 0, "repost", accessGroups, preferences);
        if (post.RepostedPostEntryResponse && post.RepostedPostEntryResponse.ProfileEntryResponse && post.RepostedPostEntryResponse.ProfileEntryResponse.Username) { postTypeInline = <> {postTypeInline} <span className="fw-bolder">{post.RepostedPostEntryResponse.ProfileEntryResponse.Username}</span></>; }
    } else if (post.ParentStakeID) {
        postType = "Reply";
        postTypeInline = <span className="fw-bolder">replied</span>;
        if (post.parentPost && post.parentPost.poster && post.parentPost.poster.username) { postTypeInline = <> {postTypeInline} to <span className="fw-bolder">{post.parentPost.poster.username}</span></>; }
    } else if (post.PostExtraData && (post.PostExtraData.BlogTitleSlug || post.PostExtraData.CoverImage)) {
        postType = "Blog";
        postTypeInline = <span className="fw-bolder">wrote an article</span>;
    }
  
        // Post type
        //if (post.parentPostHash) { console.log("Post is a reply", post.parentPostHash); }
        let hidden = '';
        let postIcon = [];

        if (post.IsHidden) { 
            hidden = " opacity-50"; 
            postIcon.push(<i key={`${post.PostHashHex}_hidden`} className="ms-2 bi bi-incognito"></i>);
            //console.log("Post is HIDDEN", post.isHidden); 
        }
        if (post.IsNFT) { 
            let nftLink = null;
            let nftTip = null;
            if (parseInt(post.NumNFTCopiesForSale) > 0) {
                // Indicate NFT for sale
                nftLink = <Link key={`${post.PostHashHex}_nft-link`} to={`/nft/${post.PostHashHex}`}><i key="nft" className="ms-2 bi bi-bag-check-fill"></i></Link>;
                nftTip = 'NFT for sale';
            } else {
                // Indicate an NFT
                nftLink = <Link key={`${post.PostHashHex}_nft-link`} to={`/nft/${post.PostHashHex}`}><i key="nft" className="ms-2 bi bi-bag-fill"></i></Link>;
                nftTip = 'NFT not currently for sale';
                
            }
            postIcon.push(
                <OverlayTrigger
                    key={`${post.PostHashHex}_nft_overlay`}
                    placement="top"
                    overlay={<Tooltip><span className='px-2'>{nftTip}</span></Tooltip>}
                >
                    {nftLink}
                </OverlayTrigger>
                );
        }
        if (post.IsPinned || post.PostExtraData && post.PostExtraData.IsPinned && post.PostExtraData.IsPinned === "true") { 
            postIcon.push(
            <OverlayTrigger
                key={`${post.PostHashHex}_pinned`}
                placement="top"
                overlay={<Tooltip><span className='px-2'>Pinned by {post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}</span></Tooltip>}
            >
                <i className="ms-2 bi bi-star-fill text-warning"></i>
            </OverlayTrigger>
            );
        }
      //if (post.isFrozen) { console.log("Post isFrozen in profile", post.isFrozen); }
  
      // Post Attachments
      const hasAttachments = (post) => {
        return post.ImageURLs || post.VideoURLs;
      };
      
      const mediaAttachments = hasAttachments(post) ? <PostMedia post={post} currentUser={currentUser} view={view} /> : null;
      
      if (mediaAttachments) {
        postAttachments.push(mediaAttachments);
      }

      if (post.PostExtraData && post.PostExtraData.EmbedVideoURL && post.PostExtraData.EmbedVideoURL !== '') {

        if(post.PostExtraData.EmbedVideoURL.includes("spotify.com")) {
            // spotify embed
            musicAttachments.push(post.PostExtraData.EmbedVideoURL);
            //console.log("[posts.js] spotify embed, post type:", type);
            postAttachments.push(
                <iframe 
                    style={{ borderRadius: "12px", margin: "0", height: view === "media" ? "352px" : view === "grid" ? "150px" : "152px" }}
                    src={`${post.PostExtraData.EmbedVideoURL}`}
                    width="100%" 
                    frameBorder="0" 
                    allowFullScreen="" 
                    className='m-0 mt-2 p-0 postAttachment'
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    title={`Embed from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s post: ${post.PostHash}`}
                    loading="lazy" 
                />
                
            );
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://mousai.stream")) {
            musicAttachments.push(post.PostExtraData.EmbedVideoURL);
            postAttachments.push(
                <iframe 
                    width="100%" 
                    style={{ margin: "0", padding: "0" }}
                    height="165px" 
                    scrolling="no" 
                    frameBorder="no" 
                    className='m-0 mt-2 p-0 postAttachment'
                    allow="autoplay" 
                    src={`${post.PostExtraData.EmbedVideoURL}`}
                    loading="lazy"
                />
            );
        } else if (post.PostExtraData.EmbedVideoURL.includes("giphy.com")) {
            function extractGiphyID(url) {
                // Define regex patterns to match different Giphy URL formats
                const patterns = [
                    /giphy\.com\/media\/([a-zA-Z0-9]+)/,   // Matches URLs like https://giphy.com/media/ID/
                    /giphy\.com\/gifs\/([a-zA-Z0-9]+)/,    // Matches URLs like https://giphy.com/gifs/ID/
                    /giphy\.com\/embed\/([a-zA-Z0-9]+)/    // Matches URLs like https://giphy.com/embed/ID/
                ];
        
                // Try each pattern to find a match
                for (let pattern of patterns) {
                    const match = url.match(pattern);
                    if (match) {
                        return match[1];
                    }
                }
                return null;
            }
        
            const giphyID = extractGiphyID(post.PostExtraData.EmbedVideoURL);
            if (giphyID) {
                const giphyEmbedURL = `https://giphy.com/embed/${giphyID}`;
        
                postAttachments.push(
                    <div className={`ratio ${view === 'grid' ? `ratio-1x1` : view === 'media' ? `ratio-4x3` : `ratio-16x9`}`} style={{ width: "100%", position: "relative" }}>
                        <iframe 
                            src={`${giphyEmbedURL}`}
                            width="100%" 
                            height="100%" 
                            style={{ position: "absolute", scrollBehavior: "hidden", background: "transparent", colorScheme: "auto" }}
                            className='giphy-embed m-0 p-0 postAttachment'
                            frameBorder="no"
                            allowFullScreen >
                        </iframe>
                    </div>
                );
            } else {
                console.warn("Unable to extract Giphy ID from URL:", post.PostExtraData.EmbedVideoURL);
            }
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://www.youtube.com")) {
            // Function to extract video ID from YouTube URL
            const extractYouTubeID = (url) => {
                const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n?#]+)/;
                const matches = url.match(regex);
                return matches ? matches[1] : null;
            };
        
            const videoID = extractYouTubeID(post.PostExtraData.EmbedVideoURL);
            if (videoID) {
                const privacyEnhancedURL = `https://www.youtube-nocookie.com/embed/${videoID}`;
                postAttachments.push(
                    <>
                        <div className={`ratio ${view === 'grid' ? `ratio-1x1` : view === 'media' ? `ratio-4x3` : `ratio-16x9`}`}>
                            <iframe 
                                frameBorder="0" 
                                allow="picture-in-picture; clipboard-write; encrypted-media; gyroscope; accelerometer; encrypted-media;" 
                                allowFullScreen="" 
                                className="mx-auto rounded-3 w-100 postAttachment videoFeedAspect" 
                                src={`${privacyEnhancedURL}?rel=0`}
                                style={{ marginTop: `-1px` }}
                                loading="lazy"
                                title={`Embed from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username : `Author`}'s post: ${post.PostHash}`}
                            />
                        </div>
                    </>
                );
            } else {
                // Handle invalid YouTube URL case
                postAttachments.push(<div>Invalid YouTube URL</div>);
            }
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://www.tiktok.com")) {
            postAttachments.push(
                <iframe 
                    //id="embed-iframe" 
                    frameBorder="0" 
                    allow="picture-in-picture; clipboard-write; encrypted-media; gyroscope; accelerometer; encrypted-media;" 
                    allowFullScreen="" 
                    className="mx-auto rounded-3" 
                    height="738" 
                    src={`${post.PostExtraData.EmbedVideoURL}&transparent=1`}
                    style={{ marginTop: `-1px` }}
                    //style={{ maxWidth: `325px` }}
                    loading="lazy"
                    title={`Embed from ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s post: ${post.PostHash}`}
                />
            );
        } else if(post.PostExtraData.EmbedVideoURL.includes("https://twitter.com/")) {
            postAttachments.push(
                <TweetEmbed url={post.PostExtraData.EmbedVideoURL} preferences={preferences} />
            );
        } else {
            postAttachments.push(
                <>
                <div style={{ width: "100%", height: "0", paddingBottom: "100%", position: "relative" }}>
                    Other - URL: {post.PostExtraData.EmbedVideoURL}<br/>
                    <iframe 
                        src={`${post.PostExtraData.EmbedVideoURL}?transparent=1`}
                        width="100%" 
                        height="100%" 
                        style={{ position: "absolute", backgroundColor: "#000" }}
                        className='m-0 p-0 postAttachment'
                        frameBorder="no"
                        allowFullScreen >
                    </iframe>
                </div>
                </>
            );
        }
    }
  
    let quickReply = '';
    let engagements = '';
    let parentPost = '';
    let readComments = '';
    let levelPadding = '';
    let wrapperClass = '';
    //console.log('[formatPost] DetFormat: Value of level after conditional check:', level);
    
    let levelInt = parseInt(level || 0, 10);
    levelInt = Math.min(levelInt, 3);
    let background = 'bg-body';

    if(levelInt===0) { 
        if(postType==="Repost") {  }
        else if (type==="single") {  wrapperClass = ' mt-0'; }
        else { levelPadding = " "; wrapperClass = " mb-3"; }
    }
    if(levelInt>0) { 
        levelPadding = ''; 
        wrapperClass = ''; 
    }
    if(repost) { wrapperClass = ' my-3'; }
    if(level<0) { levelPadding = ' ms-5'; }

    if(type==="repost") { wrapperClass = ''; type="post"; }
    if(type==="quote") { wrapperClass = ''; }

    //console.log('[formatPost] DetFormat: Type, PostType, LevelInt, wrapperClass:', type, postType, levelInt, wrapperClass);
    /*
    levelPadding = 'ms-5 ps-0';
    wrapperClass = ' mb-5';
    if(level<0) { levelPadding = ''; wrapperClass = ''; }
    else if(levelInt>=3)    { levelPadding = ' ms-1'; wrapperClass = ' border-start'; }
    else if(levelInt===2)   { levelPadding = ' ms-1'; wrapperClass = ' border-start'; }
    else if(levelInt===1)   { levelPadding = ' py-0 ps-3 small'; wrapperClass = ' ms-3 border-start ps-3'; }
    console.log('Calculated levelPadding:', levelPadding);
    */
    if (level >= 0) {
        //console.log("formatPost, Processing level >0");
        if (post.ParentPosts && post.ParentPosts.length > 0) {
            // Map each parent post to a promise of formatted parent post
            const formattedParentPostsPromises = post.ParentPosts.map(async (parentPost, index) => {
                // Determine the type and label based on whether it's the last parent post or not
                //console.log(index,post.ParentPosts.length);
                //const type = index === 0 ? 1 : 1;
                //const label = index === 0 ? 'post' : 'comment';
                return await formatPost(parentPost, currentUser, level, type, accessGroups, preferences, null, null, alternateUsers, null);
            });
        
            // Wait for all promises to resolve
            const formattedParentPosts = await Promise.all(formattedParentPostsPromises);
        
            // Assign the array of formatted parent posts to parentPost
            parentPost = formattedParentPosts;
            //if(type !== "post") { type = "comment"; }
        }
    

        let diamondValue = '';
        if (post.diamondsTotalValue && post.diamondsTotalValue.nodes[0]?.diamondsTotalValueNanos) {
            const diamondsTotalValue = (post.diamondsTotalValue.nodes[0].diamondsTotalValueNanos / 1e9).toFixed(4);
            diamondValue = <small>({diamondsTotalValue} DESO)</small>;
        }

        //console.log("Replies", post.replies);
        if (post.CommentCount > 0) {
            readComments = (
                <div id={`comments_${post.PostHashHex}`} className={`rounded-0 border-0${levelPadding}`}>
                    <Link className="load-comments text-muted" id={`link_${post.PostHashHex}`} data-posthashhex={post.PostHashHex}><i className="bi bi-chat-left-quote"></i> {post.CommentCount} replies...</Link>
                </div>
            );
        }
        /*
        if(currentUser) {
            quickReply = (
                <QuickReplyForm
                    postHash={post.PostHashHex}
                    onSubmit={handleSubmit} // Define the handleSubmit function for the onSubmit prop
                />
            );
        }
        */

        const url = '/posts/' + post.PostHashHex;
        let firstLine = null;
        let taster = null;
        if(post.Body) {
            firstLine = post.Body.split('\n')[0]; // Get the first line of the body
            taster = firstLine.length > 100 ? firstLine.substring(0, 100) + '...' : firstLine;
        }
        let title;
        if(post && post.PostExtraData && post.PostExtraData.Title) {
            title = `${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? '@'+post.ProfileEntryResponse.Username+': ' : null } ${post.PostExtraData.Title}`;
        } else {
            title = `${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? '@'+post.ProfileEntryResponse.Username+': ' : null } ${taster}`;
        }

        let featureImage;
        if(post.PostExtraData && post.PostExtraData.CoverImage) {
            featureImage = post.PostExtraData.CoverImage;
        } else if (post.ImageURLs && post.ImageURLs.length > 0) {
            featureImage = post.ImageURLs[0];
        } else if (post.post && post.poster.extraData && post.poster.extraData.FeaturedImageURL) {
            featureImage = post.poster.extraData.FeaturedImageURL;
        } else {
            featureImage = null;
        }
        const ShareLinkComponent = (
            <ShareLink
                title={title}
                taster={taster}
                url={url}
                image={featureImage}
                handleShare={handleShare}
            />
        );
        //console.log("Engagements for "+post.ProfileEntryResponse.Username+" current user: "+currentUser);
        //console.log("<formatPost> props:", currentUser, preferences, alternateUsers);
        engagements = (
                <div className="d-flex justify-content-around text-center pt-1 engagementPanel">
                    <SendReactionButton key={`reaction_${post.PostHashHex}`} PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} />
                    <SendDiamondsButton key={`diamond_${post.PostHashHex}`} PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} />
                    <SendRepostButton currentUser={currentUser} alternateUsers={alternateUsers} preferences={preferences} key={`repost_${post.PostHashHex}`} PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} post={post} />
                    <DirectTipButton key={`directip_${post.PostHashHex}`} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} Receiver={post.ProfileEntryResponse} PostHashHex={post.PostHashHex} source='post' />
                    <div className="align-self-center share-link">
                        {ShareLinkComponent}
                    </div>
                </div>
        );
    }

    if(preview) {
        engagements = null;
    }
    let cardHtml = '';
    //console.log("[posts.js] formatPost END Formatting post: level, type, thread, post: ",level,type,thread,post);

    let classCol = 'col-12'
    switch(view) {
        case 'grid':
          classCol = `col-6 col-md-4 col-lg-3 col-xl-2 mb-4`;
          break;
        case 'media':
          classCol = `col-12 col-md-6 col-lg-6 col-xl-3`;
          break;
        case 'list':
        case 'threaded':
        default:
          classCol = `col-12`;
          break;
      }

    if (view === "grid") {
        //console.log(`[posts.jsx] Outputting view: grid: `,view,postAttachments);
        if(postAttachments && postAttachments.length > 0) {
            // We also need to collect any images from blogs
            // - feature images
            // - delta images

            const postImages = post.ImageURLs || [];
            cardHtml = postImages.map((ImageURL, index) => (
                <div className={`collapse show post_id col-6 col-md-4 col-lg-2 col-xl-2 p-2`} data-id={post.PostHashHex}>
                    <ImageFeedOutput image={ImageURL} post={post} currentUser={currentUser} />
                </div>
            ));
            
            /*
            cardHtml = postAttachments.map((attachment, index) => (
                attachment ? (
                    <div key={index} className={`collapse show post_id p-2 ${classCol} ${view ==='grid' ? `ratio-grid` : `ratio-media`}`} data-id={`${post.PostHashHex}_${index}`}>
                        {attachment}
                    </div>
                ) : null
            ));
            */
        } else { cardHtml = null; }
    } else if (view === "slideshow") {
        //console.log(`[posts.jsx] Outputting view: grid: `,view,postAttachments);
        if(postAttachments && postAttachments.length > 0) {
            // We also need to collect any images from blogs
            // - feature images
            // - delta images

            const postImages = post.ImageURLs || [];
            cardHtml = postImages.map((ImageURL, index) => (
                <div className={`carousel-item`} key={index}>
                    <div className="position-absolute bottom-0 start-0 p-2 w-100 d-flex flex-row flex-nowrap justify-content-between align-items-center" style={{ zIndex: 10 }}>
                        <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center p-2">
                            <Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check} />
                            &nbsp;<Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check} />
                        </div>
                        <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center p-2">
                            <Link className="text-body text-decoration-none" to={`/posts/${post.PostHashHex}`}>view post</Link>
                        </div>
                        <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center p-2">
                            <SendDiamondsButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} currentUser={currentUser} />
                            <SendReactionButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} currentUser={currentUser} />
                        </div>
                    </div>
                    <ImageFeedOutput image={ImageURL} post={post} currentUser={currentUser} data-id={post.PostHashHex} />
                </div>
            ));
            
            /*
            cardHtml = postAttachments.map((attachment, index) => (
                attachment ? (
                    <div key={index} className={`collapse show post_id p-2 ${classCol} ${view ==='grid' ? `ratio-grid` : `ratio-media`}`} data-id={`${post.PostHashHex}_${index}`}>
                        {attachment}
                    </div>
                ) : null
            ));
            */
        } else { cardHtml = null; }
    } else if (view === 'media') {
        //console.log(`[posts.jsx] Outputting view: media: `,view,postAttachments);
        if(postAttachments && postAttachments.length > 0) {
            cardHtml = postAttachments.map((attachment, index) => (
                attachment ? (
                    <div key={index} className={`collapse show ratio-media post_id p-2 pb-5 mb-4 ${classCol} ${hidden}`} data-id={post.PostHashHex}>
                        <div className='d-flex flex-row flex-nowrap align-items-center'>
                            <Avatar suppliedProfile={post.ProfileEntryResponse} type='avatar' publicKey={post.PosterPublicKeyBase58Check} />
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: hidden ? `0.5` : `1` }}>
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                                <div className="useraction">
                                    {postTypeInline}&nbsp;
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        {attachment}
                        {!preview && ( <PostComments post={post} showPost={true} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                    </div>
                ) : null
            ));
        } else { cardHtml = null; }
    } else if (repost) {
        cardHtml = (
            <React.Fragment>
                <div className={`${wrapperClass}`} data-posthashhex={post.PostHashHex}>
                <div className={`small text-muted d-flex flex-row align-items-end`}><i className="bi bi-repeat me-1"></i> <Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check}/> <span className='ms-1 fw-light'>reposted</span></div>
                    {repost}
                </div>
            </React.Fragment>
        );
    } else if (type === "quote") {
        cardHtml = (
        <div className={`d-flex flex-nowrap align-items-center pb-3`} data-posthashhex={post.PostHashHex}>
            <i className="fs-1 mx-2 bi bi-quote"></i>
            <div className="flex-grow-1">
                <div className={`collapse p-0 show post_id`} data-id={post.PostHashHex}>
                    <div className={`p-0 m-0 p-2 text-nowrap`}>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                            <div className="fs-4">
                                <Avatar  suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/>
                            </div>
                            <div className="ps-2 flex-grow-1">
                                <Avatar  suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check}/>
                                <div className="small text-muted usertime">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>{formattedTimestamp}</Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`m-0 ${levelPadding} pb-0 mb-0 card-secondary card-effect`}>
                        <BodyOutput post={post} padding={2} preview={preview} expanded={isExpanded} />
                        {postAttachments.map((attachment, index) => (
                            <>
                                {attachment}
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        );
    } else if (postType === "Blog" && type!=="media" && type!=="video") {
        if(type==="post") {
            // Preview
            if (post.PostExtraData.CoverImage) {
                var blogImage = <img src={post.PostExtraData.CoverImage} className="object-fit-cover card-effect" alt={`Cover image for ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s article, '${post.PostExtraData.Title}'`} />;
            } else if (post.ProfileEntryResponse.ExtraData && post.ProfileEntryResponse.ExtraData.FeaturedImageURL) {
                var blogImage = <img src={post.ProfileEntryResponse.ExtraData.FeaturedImageURL} className="object-fit-cover card-effect" alt={`Cover image for ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s article, '${post.PostExtraData.Title}'`} />;
            } else { var blogImage = ''; }
            
            cardHtml = (
                <Link to={`/posts/${post.PostHashHex}`} className={`collapse show post_id col-12 mb-4`} data-id={post.PostHashHex}>
                    <div data-posthashhex={post.PostHashHex} className="card m-0 border-1 lh-sm card-effect">
                        <div className="ratio ratio-16x9">{blogImage}</div>
                        <div className="position-absolute bottom-10 bottom-0 p-2 w-100 d-flex flex-row flex-nowrap justify-content-between align-items-center" style={{ zIndex: 10 }}>
                            <div className="rounded-2 bg-body bg-opacity-75 p-2">
                                <h5 className='fs-5 d-inline'>{post.PostExtraData.Title}</h5>
                                <abbr title={formattedTimestamp} className='ms-2 small'>{timeSince}</abbr>
                                <div className='d-flex flex-row flex-nowrap align-items-center justify-content-between'>
                                    <div className="d-flex align-items-center p-2">
                                        <Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check} />
                                        &nbsp;<Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check} />
                                    </div>
                                    <div className="d-flex align-items-center p-2 text-body">
                                        <SendDiamondsButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} currentUser={currentUser} />
                                        <SendReactionButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} currentUser={currentUser} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </Link>
            );
        } else {
            // Reading the blog

            /* ExtraData:
            {
                "BlogDeltaRtfFormat": "{\"ops\":[{\"insert\":\"There are only
                "BlogPostIsPinned": "false",
                "BlogTitleSlug": "b-17-flying-fortress-point-of-view-as-a-modern-day-crew-chief",
                "CoverImage": "https://images.deso.org/7c2f92829b4f94b1bf92fcfea49455783db6d763983be7ecdeeb7ae7310dfbe8.webp",
                "DNI-ZirkelsBlogContent": "{\"ops\":[{\"insert\":\"During WWII, there were 12,731 
                "DNI-ZirkelsBlogURL": "https://zirkels.com/a/b-17-flying-fortress-point-of-view-as-a-modern-day-crew-chief",
                "DerivedPublicKey": "\u0003\b���$gե���y\u000f�6|�\nx�\u001f.��ʳ)G:\ftM",
                "Description": "During WWII,",
                "Language": "en-US",
                "Node": "DesoNoCode",
                "Title": "B-17G Flying Fortress: Point of view from a modern-day Crew Chief"
            } */

            if (post.PostExtraData.CoverImage) {
                var blogImage =
                    <>
                        <img src={post.PostExtraData.CoverImage} className="feature reflect p-0 m-0 object-fit-cover" alt={`Cover image for ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s article, '${post.PostExtraData.Title}'`} />
                        <img src={post.PostExtraData.CoverImage} className="feature p-0 m-0 object-fit-cover" alt={`Cover image for ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s article, '${post.PostExtraData.Title}'`} />
                    </>;
            } else { var blogImage = ''; }

            if (post.poster && post.poster.extraData && post.poster.extraData.DisplayName && post.poster.extraData.DisplayName !== '') {
                usernameOutput = post.poster.extraData.DisplayName.trim();
            } else {
                usernameOutput = `${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}`;
            }
            cardHtml = (
            <>
                
                <div data-posthashhex={post.PostHashHex} className='container'>
                    <div className="row">
                        <div className={`collapse show post_id col-12 mt-0 p-0 pb-3`} data-id={post.PostHashHex}>
                            <div>
                                <div className="m-0 border-0 bg-opacity p-0 m-0">
                                    <div className="m-0 border-0 lh-sm rounded-0">
                                        <div className='p-3'>
                                            <h1 className='fs-2'>{post.PostExtraData.Title}</h1>
                                            <h5 className="d-flex flex-row"><Avatar  suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/><Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check}/><span className='ms-2 text-muted'><abbr title={formattedTimestamp}>{timeSince}</abbr></span></h5>
                                        </div>
                                        <div className='' style={{ fontSize: "130% !important" }}>
                                            <FormattedBlog post={post} />
                                        </div>
                                        {engagements}
                                    </div>
                                </div>
                            </div>
                            
                            {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread} /> )}
                        </div>
                    </div>
                </div>
            </>
            );
        }
    } else if (type === "post") {
        cardHtml = (
            <div data-posthashhex={post.PostHashHex} className={`collapse show post_id col-12 mx-auto${wrapperClass}`} data-id={post.PostHashHex}>
                {parentPost}
                <div className={`border-0 rounded-0 p-0 m-0 pt-2 text-nowrap message-header`}>
                    <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className='col flex-fill d-flex flex-col align-items-center'>
                            <div className="ps-2 fs-4" style={{ opacity: hidden ? `0.5` : `1` }}><Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/></div>
                            <div className="ps-2 flex-grow-1 justify-content-start align-items-center flex-wrap text-truncate" style={{ opacity: hidden ? `0.5` : `1` }}>
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                                <div className="useraction">
                                    {postTypeInline}&nbsp;
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            {postIcon && postIcon !== '' && <div className="fs-5 me-2">{postIcon}</div>}
                        </div>
                    </div>
                </div>
                <div className={`position-relative ${background} rounded-0 border-0 p-0 m-0 ${levelPadding} card-primary card-effect`}>
                    {!preview && ( <PostSettings post={post} currentUser={currentUser} preferences={preferences} /> )}
                    <BodyOutput post={post} maxLength={164} padding={3} hidden={hidden} preview={preview} expanded={isExpanded} />
                    {postAttachments.map((attachment, index) => (
                        <React.Fragment>
                            {attachment}
                        </React.Fragment>
                    ))}
                </div>
                { postQuoted && (
                    <div className={`mt-3 ${levelPadding}`}>{postQuoted}</div>
                )}
                <div className={` ${levelPadding}`}>
                    { !hidden && (
                        <>
                            {engagements}
                            
                        </>
                    )}
                    {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                </div>
            </div>
        );
    } else if (type === "comment") {
        cardHtml = (
                <div className={`p-0 m-0 repliesContainer${(post.CommentCount > 0) ? ` hasComments` : ``}`} id={`post_`+post.PostHashHex} >
                    <div className='repliesWrapper w-100 d-flex justify-content-between align-items-top flex-nowrap p-0 m-0'>
                        <div style={{ width: `27px`}}>
                            <Avatar type="avatar" size={1} publicKey={post.PosterPublicKeyBase58Check}/>
                        </div>
                        <div className={`flex-fill flex-grow-1`}>
                            <div>
                                <div className='replyContainer bg-body-tertiary Cd-block card-tertiary rounded-3'>
                                    <Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check}/>
                                    <span className='small'>
                                        <span className='text-muted'>{postTypeInline}</span>&nbsp;
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                            >
                                            <span>{timeSince}</span>
                                        </OverlayTrigger>
                                    </span>
                                    <BodyOutput post={post} maxLength={164} comment={true} preview={preview} expanded={isExpanded} />
                                    {postAttachments.map((attachment, index) => (
                                        <React.Fragment>
                                            {attachment}
                                        </React.Fragment>
                                    ))}
                                    {postQuoted}
                                </div>
                                {engagements}
                            </div>
                            {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread} /> )}
                        </div>
                    </div>
                </div>
        );
    } else if (type === "embed") {
        if(!postAttachments || postAttachments.length < 1) { return; }
        cardHtml = (
            <div data-posthashhex={post.PostHashHex} className={`collapse show post_id col-12 mx-auto${wrapperClass}`} data-id={post.PostHashHex}>
                {/*parentPost*/}
                <div className={`border-0 rounded-0 p-0 m-0 pt-2 text-nowrap message-header`}>
                    <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className='col flex-fill d-flex flex-col align-items-center'>
                            <div className="ps-2 fs-4" style={{ opacity: hidden ? `0.5` : `1` }}><Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/></div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: hidden ? `0.5` : `1` }}>
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                                <div className="useraction">
                                    {postTypeInline}&nbsp;
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        {postIcon && postIcon !== '' && <div className="col fs-5 me-2">{postIcon}</div>}
                    </div>
                </div>
                {postAttachments.map((attachment, index) => {
                    return (
                        <>
                            {attachment}
                        </>
                    );
                })}

                { !hidden && (
                    <>
                        {engagements}
                        {!preview && ( <PostComments post={post} showPost={true} currentUser={currentUser} level={(level+1)} thread={thread} /> )}
                    </>
                )}
            </div>
        );
    } else if (type === "music") {
        if(!musicAttachments || musicAttachments.length < 1) { return; }
        cardHtml = (
            <div data-posthashhex={post.PostHashHex} className={`collapse show post_id col-12 mx-auto${wrapperClass}`} data-id={post.PostHashHex}>
                {/*parentPost*/}
                <div className={`border-0 rounded-0 p-0 m-0 pt-2 text-nowrap message-header`}>
                    <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className='col flex-fill d-flex flex-col align-items-center'>
                            <div className="ps-2 fs-4" style={{ opacity: hidden ? `0.5` : `1` }}><Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/></div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: hidden ? `0.5` : `1` }}>
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                                <div className="useraction">
                                    {postTypeInline}&nbsp;
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        {postIcon && postIcon !== '' && <div className="col fs-5 me-2">{postIcon}</div>}
                    </div>
                </div>
                {musicAttachments.map((attachment, index) => {
                    if (attachment.includes("spotify.com")) {
                        return (
                            <iframe
                                style={{ borderRadius: "12px", margin: "0" }}
                                src={attachment}
                                className='card-effect overflow-hidden postAttachment'
                                width="100%"
                                height="352"
                                allowFullScreen=""
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                title="Spotify Embed"
                                loading="lazy"
                            />
                        );
                    } else if (attachment.includes("https://mousai.stream")) {
                        // Render other embed URLs
                        return (
                            <iframe
                                width="100%"
                                className='card-effect overflow-hidden postAttachment'
                                style={{ margin: "0" }}
                                height="165"
                                scrolling="no"
                                frameBorder="no"
                                allow="autoplay"
                                src={attachment}
                            />
                        );
                    } else {
                        // Handle unsupported embed types
                        return (
                            <p key={index}>Unsupported embed type</p>
                        );
                    }
                })}

                { !hidden && (
                    <>
                        {engagements}
                        {!preview && ( <PostComments post={post} showPost={true} currentUser={currentUser} level={(level+1)} thread={thread} /> )}
                    </>
                )}
            </div>
        );
    } else if (type === "media") {
        const postImages = post.ImageURLs || []; // Ensure post.ImageURLs is an array

        // We also need to collect any images from blogs
        // - feature images
        // - delta images

        /*
        cardHtml = postImages.map((ImageURL, index) => (
            <div className={`collapse show post_id col-6 col-md-4 col-lg-2 col-xl-2 p-2`} data-id={post.PostHashHex}>
                <ImageFeedOutput image={ImageURL} post={post} currentUser={currentUser} />
            </div>
        ));
        */
        cardHtml = postAttachments.map((attachment, index) => (
            <div className={`collapse show post_id col-6 col-md-4 col-lg-2 col-xl-2 p-2`} data-id={post.PostHashHex}>
                {attachment}
            </div>
        ));
    } else if (type === "video") {
        cardHtml = (
            <React.Fragment>
                {postAttachments.map((attachment, index) => (
                <div key={index} className={`collapse show post_id col-12 col-md-6 col-lg-4 p-2 pb-5 mb-4 ${hidden}`} data-id={post.PostHashHex}>
                    <div className='d-flex flex-row flex-nowrap align-items-center'>
                        <Avatar suppliedProfile={post.ProfileEntryResponse} type='avatar' publicKey={post.PosterPublicKeyBase58Check} />
                        <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: hidden ? `0.5` : `1` }}>
                            <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                            <div className="useraction">
                                {postTypeInline}&nbsp;
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                    >
                                    <span>{timeSince}</span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    {attachment}
                    {!preview && ( <PostComments post={post} showPost={true} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                </div>
                ))}
            </React.Fragment>
        );
    } else if (type === 'pinned') {
        cardHtml = (
            <div className={`collapse show post_id col-12 mx-auto mb-5`} data-posthashhex={post.PostHashHex}>
                {/*parentPost*/}
                <div className={`border-0 rounded-0 p-0 m-0 pt-2 text-nowrap message-header`}>
                    <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className='col flex-fill d-flex flex-col align-items-center'>
                            <div className="ps-2 fs-4" style={{ opacity: hidden ? `0.5` : `1` }}><Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/></div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: hidden ? `0.5` : `1` }}>
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                                <div className="useraction">
                                    {postTypeInline}&nbsp;
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        {postIcon && postIcon !== '' && <div className="col fs-5 me-2">{postIcon}</div>}
                    </div>
                </div>
                <div className={levelPadding}>{postQuoted}</div>
                <div className={`position-relative rounded-0 border-0 p-0 m-0 ${levelPadding}`}>
                    {!preview && ( <PostSettings post={post} currentUser={currentUser} preferences={preferences} /> )}
                    <BodyOutput post={post} maxLength={164} padding={3} hidden={hidden} background={background} preview={preview} expanded={isExpanded} />
                    {postAttachments.map((attachment, index) => (
                        <React.Fragment>
                            {attachment}
                        </React.Fragment>
                    ))}
                </div>
                { !hidden && (
                    <div className={`border-0 p-0 m-0 engagementPanel ${levelPadding}`}>
                        {engagements}
                    </div>
                )}
                {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread} /> )}
            </div>
        );
    } else {
        cardHtml = (
            <div className={`collapse show post_id col-12 mx-auto${wrapperClass}`} data-posthashhex={post.PostHashHex}>
                {/*parentPost*/}
                <div className={`border-0 rounded-0 p-0 m-0 pt-2 text-nowrap message-header`}>
                    <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className='col flex-fill d-flex flex-col align-items-center'>
                            <div className="ps-2 fs-4" style={{ opacity: hidden ? `0.5` : `1` }}><Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/></div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: hidden ? `0.5` : `1` }}>
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                                <div className="useraction">
                                    {postTypeInline}&nbsp;
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        {postIcon && postIcon !== '' && <div className="col fs-5 me-2">{postIcon}</div>}
                    </div>
                </div>
                { postQuoted && (
                    <div className={levelPadding}>{postQuoted}</div>
                )}
                <div className={`position-relative rounded-0 ${background} border-0 p-0 m-0 ${levelPadding} card-effect`}>
                    {!preview && ( <PostSettings post={post} currentUser={currentUser} preferences={preferences} /> )}
                    <BodyOutput post={post} maxLength={164} padding={3} hidden={hidden} preview={preview} expanded={isExpanded} />
                    {postAttachments.map((attachment, index) => (
                        <React.Fragment>
                            {attachment}
                        </React.Fragment>
                    ))}
                </div>
                    { !hidden ? engagements : null }
                    {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread} /> )}
            </div>
        );
    }

    if(moderationOutcome !== true) {
        return cardHtml ? <span id={`${post.PostHashHex}_mask`} className={`maskContent collapse collapsed`} data-bs-target={`#${post.PostHashHex}_mask`} data-bs-toggle="collapse">{cardHtml}</span> : null;
    } else {
        return cardHtml ? cardHtml : null;
    }
  }

export default formatPost;


export const PostSettings = ({ post, currentUser, preferences }) => {
    const [showOptions, setShowOptions] = useState(false);

    const toggleShowOptions = (event) => {
        if (event) { event.stopPropagation(); }
        setShowOptions(prevState => !prevState);
    }

    if(!post || !currentUser) { return; } 
    
    if(post.PosterPublicKeyBase58Check != currentUser.PublicKeyBase58Check) { 
        // Settings for other's posts
        return (
            <div className="position-absolute text-end top-0 end-0 p-0" style={{ zIndex: "100" }}>
                <div className="text-end p-0 m-0">
                    <Bookmark post={post} currentUser={currentUser} /> 
                </div>
                {showOptions && (
                    <div className="post-settings-menu text-start bg-body-tertiary border rounded shadow" style={{ zIndex: "10000" }}>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <button className="btn d-block w-100 text-start" onClick={updateBookmark}><i className="bi bi-bookmark-star-fill me-2"></i>Bookmark Post</button>
                            </li>
                            <li className="nav-item">
                                <button className="btn d-block w-100 text-start"><i className="bi bi-flag-fill me-2"></i>Flag Content</button>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="position-absolute text-end top-0 end-0 p-0" style={{ zIndex: "100" }}>
            <div className="btn text-end">
                <button className="btn m-0 p-0" onClick={toggleShowOptions}><i className='bi bi-gear'></i></button>
            </div>
            {showOptions && (
                <div className="post-settings-menu text-start bg-body-tertiary border rounded shadow" style={{ zIndex: "10000" }}>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <button className="btn d-block w-100 text-start" onClick={() => handlePinPost(post, currentUser, preferences)}><i className="bi bi-pin-angle me-2"></i>Pin Post</button>
                        </li>
                        <li className="nav-item">
                            <button className="btn d-block w-100 text-start"><i className="bi bi-pencil me-2"></i>Edit Post</button>
                        </li>
                        <li className="nav-item">
                            <button className="btn d-block w-100 text-start"><i className="bi bi-eye-slash me-2"></i>Hide Post</button>
                        </li>
                        <li className="nav-item">
                            <button className="btn d-block w-100 text-start"><i className="bi bi-flag-fill me-2"></i>Flag Content</button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export const handlePinPost = async (post, currentUser, preferences) => {
    const userLocale = navigator.language || navigator.userLanguage;
    try {
        //console.log("[posts.js] pinPost:", post, currentUser);

        // Define your payload structure
        const newValue = post.IsPinned === false ? true : false;
        post.PostExtraData["IsPinned"] = newValue ? "true" : "false";
        const payload = {
            UpdaterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            PostHashHexToModify: post.PostHashHex,
            ParentStakeID: post.ParentStakeID,
            BodyObj: {
                Body: post.Body, // Placeholder for body content
                ImageURLs: post.ImageURLs, // Placeholder for image URLs
                VideoURLs: post.VideoURLs, // Placeholder for video URLs
            },
            isPinned: newValue, // Toggle the pin status
            IsHidden: post.IsHidden, // Toggle the pin status
            RepostedPostHashHex: post.RecloutedPostEntryResponse ? post.RecloutedPostEntryResponse.PostHashHex : null,
            PostExtraData: post.PostExtraData,
            TransactionFees: [localFees()], // Placeholder for transaction fees
        };

        //console.log("[posts.js] pinPost - submit post:", payload);
        const response = await submitPost(payload);
        //console.log("[posts.js] pinPost - submit response:", response);
        // Check if the request was successful
        if (!response.ok) {
            throw new Error('Failed to pin post');
        }

        // If successful, parse the response JSON
        const data = await response.json();

        // Handle the result if needed
        //console.log('Post pinned:', data);

        // Additional logic after pinning the post, if needed
    } catch (error) {
        // Handle any errors
        //console.error('Error pinning post:', error);
    }
};
  


const ImageFeedOutput = ({ image, post = null, currentUser }) => {
    // Define state to manage the images in the carousel and modal visibility
    const [carouselImages, setCarouselImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const handleImageClick = () => {
        // Add the clicked image to the carousel state
        setCarouselImages(prevImages => {
            const newImages = [...prevImages, image];
            // Set the current image index to the index of the clicked image
            setCurrentImageIndex(newImages.length - 1); // Set the index to the last image in the carousel
            return newImages;
        });
        // Open the modal by setting showModal to true
        setShowModal(true);
    };
    
    

    const handleCloseModal = () => {
        // Close the modal by setting showModal to false
        setShowModal(false);
        // Optionally, you can clear the carouselImages state if you want to reset the carousel when the modal is closed
        setCarouselImages([]);
    };

    //console.log(carouselImages);
    return (
        <>
            <img
                src={image}
                style={{ width: "100%", cursor: 'pointer' }}
                className="img-fluid imageFeedAspect object-fit-cover"
                alt="Post Image"
                onClick={handleImageClick}
            />
            {/* Conditionally render the modal */}
            {showModal && (
                <ImageFeedModal
                    images={carouselImages}
                    post={post}
                    currentIndex={currentImageIndex}
                    onClose={handleCloseModal}
                    setCurrentImageIndex={setCurrentImageIndex}
                    currentUser={currentUser}
                />
            )}
        </>
    );
};

const ImageFeedModal = ({ images, post, currentIndex, onClose, setCurrentImageIndex, currentUser }) => {
    const handleSelect = (selectedIndex, e) => {
        setCurrentImageIndex(selectedIndex);
    };

    const handleOutsideClick = (e) => {
        // Check if the click occurred outside the modal content
        if (e.target.classList.contains('modal')) {
            onClose(); // Close the modal if the click is outside the modal content
        }
    };

    // Calculate maxHeight as the viewport height - 120px
    const maxHeight = window.innerHeight - 125;
    return (
        <div>
            <div className="modal-backdrop show" style={{ display: 'block', zIndex: 30000 }}></div>
            <div className="modal modal-xl feed-carousel d-flex align-items-center justify-content-center" style={{ display: 'block', zIndex: 30010 }} onClick={handleOutsideClick}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content" style={{ maxHeight: `${maxHeight}px` }}>
                        <div className="position-absolute bottom-0 start-0 p-2 w-100 d-flex flex-row flex-nowrap justify-content-between align-items-end" style={{ zIndex: 10 }}>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex flex-column align-items-center p-2">
                                <span className='my-1'><SendDiamondsButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} currentUser={currentUser} /></span>
                                <span className='my-1'><SendReactionButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} currentUser={currentUser} /></span>
                            </div>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center p-2">
                                <Link className="text-body text-decoration-none" to={`/posts/${post.PostHashHex}`}>view post</Link>
                            </div>
                        </div>
                        <div className="w-100 position-absolute top-0 end-0 p-2 d-flex flex-row flex-nowrap justify-content-between align-items-center" style={{ zIndex: 10 }}>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center p-2">
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check} />
                                &nbsp;<Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check} />
                            </div>
                            <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center">
                                <a 
                                    className="p-2 px-3 text-body" 
                                    onClick={onClose} // Call the onClose function when the button is clicked
                                    aria-label="Close"
                                    style={{ zIndex: 10 }} // Ensure the button is above other elements
                                >
                                    <i className="bi bi-x-lg"></i>
                                </a>
                            </div>
                        </div>
                        <div className="modal-body p-0">
                            <Carousel activeIndex={currentIndex} onSelect={handleSelect} controls={images.length > 1 ? true : false} indicators={false}  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                {images.map((image, index) => (
                                    <Carousel.Item key={index}>
                                        <TransformWrapper>
                                            <TransformComponent>
                                                <img src={image} className="d-block w-100" alt="Post Image" />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const convertDeltaToHTML = (delta) => {
    const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
    return converter.convert();
};


const FormattedBlog = ({ post }) => {
    const [quillInitialized, setQuillInitialized] = React.useState(false);
    const divRef = React.useRef(null);
    let delta;
    let deltaObject = null;

    if (post.PostExtraData && post.PostExtraData['DNI-ZirkelsBlogContent']) {
        delta = post.PostExtraData['DNI-ZirkelsBlogContent'];
    } else if (post.PostExtraData && post.PostExtraData.BlogDeltaRtfFormat) {
        delta = post.PostExtraData.BlogDeltaRtfFormat;
    }

    React.useEffect(() => {
        if (!delta || !divRef.current) return;

        try {
            deltaObject = JSON.parse(delta);
            const quill = new Quill(divRef.current, { readOnly: true });
            quill.setContents(deltaObject.ops);
            setQuillInitialized(true);
        } catch (error) {
            // If parsing as JSON fails, assume it's a Markdown string
            // You can also add additional validation to ensure it's a Markdown string
            setQuillInitialized(false);
        }
    }, [post]);

    if (!delta) {
        // If delta parsing fails or delta is not present, return markdown/plain body
        return post.PostExtraData.bodyMarkdown ? (
            <ReactMarkdown>{post.PostExtraData.bodyMarkdown}</ReactMarkdown>
        ) : (
            <div>{nl2br(post.Body)}</div>
        );
    }

    return <div ref={divRef} />;
};




const handleShare = async ({ title, taster, url }) => {
    try {
        if (navigator.share) {
            await navigator.share({
                title: title,
                text: taster,
                url: url
            });
        } else {
            alert('Web Share API not supported in your browser.');
        }
    } catch (error) {
        // console.error('Error sharing:', error.message);
    }
};

export const ShareLink = ({ title, taster, url, ImageURL, handleShare }) => {
    const handleShareClick = () => {
        handleShare({ title, taster, url, ImageURL }); // Include imageUrl in the shared data
    };

    return (
        <i className="engagementAction bi bi-share-fill share-link" data-content-type="post" onClick={handleShareClick}></i>
    );
};

export const getPostAPI = async (postHash, currentUser, preferences) => {
    try {
        const request = {
            PostHashHex: postHash,
            FetchParents: true,
        };
        if (currentUser) {
            request.ReaderPublicKeyBase58Check = currentUser?.PublicKeyBase58Check;
        }
        //console.log("Preferences at getPostAPI:",preferences);
        const data = await deso_api('get-single-post', request, preferences.nodeURI); // assuming preferences is defined somewhere
        if(data && data.PostFound) { return data.PostFound; }
        else { return null; }
    } catch (error) {
        throw new Error("Error fetching post: " + error.message);
    }
};

/***********************************************************************
 * Comments
 */



export function BodyOutput({ post, maxLength = 164, comment = false, description = false, padding = 0, hidden = null, background = '', preview, expanded = null, onClickFunction }) {
    const [showFullContent, setShowFullContent] = useState(false);
    const [contentHeightExceedsMaxLength, setContentHeightExceedsMaxLength] = useState(false);
    const { accessGroups } = useContext(AccessGroupsContext);
    const { currentUser } = useContext(DeSoIdentityContext);
    const [divHeight, setDivHeight] = useState(maxLength);
    const [showExtraData, setShowExtraData] = useState(false);
    const divRef = useRef(null); 
    const [outputPost, setOutputPost] = useState(post);
    const [decrypting, setDecrypting] = useState(false);
    const [decryptedContent, setDecryptedContent] = useState(null);

    //console.log('[posts.js] HERE ref compose.js BodyOutput ',post);
    
    useEffect(() => {
        setOutputPost(post);
    }, [post])

    useEffect(() => {
        const postPreview = document.getElementById(`preview${outputPost.PostHashHex}`);
        if (postPreview && !expanded) {
            const heightDifference = postPreview.scrollHeight - maxLength;
            //setContentHeightExceedsMaxLength(heightDifference > 0); // Assuming 1em = 16px
            if(heightDifference > (85)) {
                setShowFullContent(false);
                setContentHeightExceedsMaxLength(true);
            } else {
                setShowFullContent(true);
                setContentHeightExceedsMaxLength(false);
                setDivHeight("none");
            }
        } else if (expanded) { 
            setShowFullContent(true); 
            setContentHeightExceedsMaxLength(false);
        }
    }, [outputPost.Body, outputPost.PostExtraData.bodyMarkdown, outputPost.PostExtraData.Title, outputPost.PostHashHex, maxLength]);

    const toggleReadMore = () => {
        setShowFullContent(prev => !prev);
    };

    const toggleExtraData = () => {
        setShowExtraData((prev) => {
            const newShowExtraData = !prev;
            if (newShowExtraData) {
                setShowFullContent(true);
            }
            return newShowExtraData;
        });
    };

    let type = 1; // standard
    if (outputPost.PostExtraData && (outputPost.PostExtraData.bodyMarkdown || outputPost.PostExtraData.md)) {
        type = 2; // markdown
        if(outputPost.PostExtraData && outputPost.PostExtraData.md && !outputPost.PostExtraData.bodyMarkdown) { 
            outputPost.PostExtraData.bodyMarkdown = outputPost.PostExtraData.md; 
        }
    } else if (outputPost.PostExtraData && (outputPost.PostExtraData.isMarkdown)) {
        type = 2; // markdown
        outputPost.PostExtraData.bodyMarkdown = outputPost.Body; 
    }
    if (outputPost.PostExtraData && outputPost.PostExtraData.BlogDeltaRtfFormat) {
        type = 3; // quill delta Rtf
    }
    if (outputPost.PostExtraData && (outputPost.PostExtraData.SubscriptionEncryptedPostBody || outputPost.PostExtraData.SubscriptionAccessGroupKeyName)) {
        type = 4; // subscribers only

        //console.log("[posts.js] <BodyOutput SUBSCRIPTIONS");
        //console.log("[posts.js] <BodyOutput SUBSCRIPTIONS post:", post);
    }

    useEffect(() => {
        const decryptContent = async () => {
            if (type === 4 && outputPost.PostExtraData.SubscriptionEncryptedPostBody && identity && currentUser) {
                //console.log('[posts.jsx] decryptContent ...:',outputPost);
                const partyAccessGroupRequest = {
                    /*
                    SenderPublicKeyBase58Check: post.PosterPublicKeyBase58Check, // post.PostExtraData.SubscriptionAccessGroupOwnerPublicKey,
                    SenderAccessGroupKeyName: post.PostExtraData.SubscriptionAccessGroupKeyName,
                    RecipientPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                    //RecipientAccessGroupKeyName: post.PostExtraData.SubscriptionAccessGroupOwnerPublicKey,
                    RecipientAccessGroupKeyName: 'default-key' // post.PostExtraData.SubscriptionAccessGroupKeyName // 
                    */
                    /*
                    RecipientPublicKeyBase58Check: outputPost.PostExtraData.SubscriptionAccessGroupOwnerPublicKey,
                    RecipientAccessGroupKeyName: outputPost.PostExtraData.SubscriptionAccessGroupKeyName,
                    SenderPublicKeyBase58Check: outputPost.PosterPublicKeyBase58Check,
                    SenderAccessGroupKeyName: 'default-key' // post.PostExtraData.SubscriptionAccessGroupKeyName // 
                    */
                    SenderPublicKeyBase58Check: outputPost.PostExtraData.SubscriptionAccessGroupOwnerPublicKey,
                    SenderAccessGroupKeyName: outputPost.PostExtraData.SubscriptionAccessGroupKeyName,
                    RecipientPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                    RecipientAccessGroupKeyName: 'default-key' // post.PostExtraData.SubscriptionAccessGroupKeyName // 
                }
                let partyAccessGroups;
                try {
                    partyAccessGroups = await checkPartyAccessGroups(partyAccessGroupRequest)
                    //console.log('[posts.jsx] partyAccessGroups:', partyAccessGroupRequest, partyAccessGroups);
                } catch (error) {
                    console.error("[modalCompose.jsx] Error decrypting message:", error);
                }

                if(!partyAccessGroups) { setDecryptedContent(false); return; }

                let thisAccessGroup;

                var decryptionPayload = {
                    ChatType: "GroupChat",
                    IsSender: (currentUser.PublicKeyBase58Check === outputPost?.PosterPublicKeyBase58Check), //  === partyAccessGroups.SenderPublicKeyBase58Check), // (currentUser.PublicKeyBase58Check === post.PostExtraData.SubscriptionAccessGroupOwnerPublicKey),
                    RecipientInfo: {
                        OwnerPublicKeyBase58Check: partyAccessGroups.RecipientPublicKeyBase58Check,
                        AccessGroupPublicKeyBase58Check: partyAccessGroups.RecipientAccessGroupPublicKeyBase58Check,
                        AccessGroupKeyName: partyAccessGroups.RecipientAccessGroupKeyName,
                    },
                    SenderInfo: {
                        OwnerPublicKeyBase58Check: partyAccessGroups.SenderPublicKeyBase58Check,
                        AccessGroupPublicKeyBase58Check: partyAccessGroups.SenderAccessGroupPublicKeyBase58Check,
                        AccessGroupKeyName: partyAccessGroups.SenderAccessGroupKeyName
                    },
                    MessageInfo: {
                        EncryptedText: outputPost?.PostExtraData?.SubscriptionEncryptedPostBody,
                        TimestampNanos: outputPost?.TimestampNanos,
                        TimestampNanosString: outputPost?.TimestampNanos,
                        /*ExtraData: {} 
                            ImageURLs: post?.PostExtraData?.SubscriptionImageURLs,
                            VideoURLs: post?.PostExtraData?.SubscriptionVideoURLs,
                            EmbedVideoURL: post?.PostExtraData?.SubscriptionEmbedVideoURL
                        }*/
                    }
                };
                
                //console.log('[posts.jsx] accessGroups: ',accessGroups);
                
                const combinedAccessGroups = [
                    ...(accessGroups?.AccessGroupsOwned || []),
                    ...(accessGroups?.AccessGroupsMember || []),
                ];
                
                const matchingAccessGroup = accessGroups?.AccessGroupsMember?.find(
                    (group) => {
                        //console.log("[posts.jsx] Checking group: ", group.AccessGroupKeyName); // Log each group's name being checked
                        return group.AccessGroupKeyName === decryptionPayload.SenderInfo.AccessGroupKeyName;
                    }
                );
            
                if (matchingAccessGroup) {
                    thisAccessGroup = matchingAccessGroup;
                    setDecrypting(true);
                    console.log("[posts.jsx] thisAccessGroup: ", thisAccessGroup);
                } else {
                    //console.log("[posts.jsx] No matching access group found.");
                }
                //

                console.log('[posts.js] Subscription Content decryptMessage: payload, accessGroup:',decryptionPayload,thisAccessGroup);
                //var decryptedMessage = await identity.decryptMessage(decryptionPayload.MessageInfo, thisAccessGroup);

                
                
                try {
                    const decryptedMessage = await identity.decryptMessage(decryptionPayload, accessGroups.AccessGroupsMember); // thisAccessGroup.AccessGroupMemberEntryResponse);
                    console.log('[posts.js] Subscription Content decryptionPayload, decryptedMessage, accessGroups.AccessGroupsMember, thisAccessGroup:',decryptionPayload, decryptedMessage, accessGroups.AccessGroupsMember, thisAccessGroup);
                    const updatedPost = {
                        ...outputPost
                    };
                    updatedPost.PostExtraData.bodyMarkdown = decryptedMessage.DecryptedMessage;

                    if(outputPost?.PostExtraData?.SubscriptionImageURLs) { 
                        decryptionPayload.MessageInfo.EncryptedText = outputPost?.PostExtraData?.SubscriptionImageURLs;
                        const decryptedImageURLs = await identity.decryptMessage(decryptionPayload, combinedAccessGroups);
                        //console.log("[modalCompose.jsx] Decrypt Images: ",decryptedImageURLs);
                        updatedPost.ImageURLs = JSON.parse(decryptedImageURLs.DecryptedMessage) || [];
                    }
                    if(outputPost?.PostExtraData?.SubscriptionVideoURLs) { 
                        decryptionPayload.MessageInfo.EncryptedText = outputPost?.PostExtraData?.SubscriptionVideoURLs;
                        const decryptedVideoURLs = await identity.decryptMessage(decryptionPayload, combinedAccessGroups);
                        updatedPost.ImageURLs = JSON.parse(decryptedVideoURLs.DecryptedMessage) || [];
                    }
                    if(outputPost?.PostExtraData?.SubscriptionEmbedVideoURL) { 
                        decryptionPayload.MessageInfo.EncryptedText = outputPost?.PostExtraData?.SubscriptionEmbedVideoURL;
                        const decryptedEmbedUrl = await identity.decryptMessage(decryptionPayload, combinedAccessGroups);
                        updatedPost.ImageURLs = decryptedEmbedUrl.DecryptedMessage || null;
                    }

                    //console.log('[posts.jsx] decryptedMessage ****:', decryptionPayload, partyAccessGroups, decryptedMessage);
                    setOutputPost(updatedPost);
                    setDecrypting(false);
                    setDecryptedContent(true);
                } catch (error) {
                    console.error("[posts.js] Subscription Content Error decrypting message:", error);
                }
            } else {
                setDecryptedContent(false);
            }
        };

        if(accessGroups && type === 4) { decryptContent(); } else {
            setOutputPost(post);
        }
    }, [outputPost.PostExtraData, identity, currentUser, type, accessGroups]);

    let bodySize = '';
    if (type === 1 && !contentHeightExceedsMaxLength && !comment && !description) {
        if (!outputPost.Body) {

        } else if (outputPost.Body.length < 10) {
            bodySize = ' fs-2';
        } else if (outputPost.Body.length < 25) {
            bodySize = ' fs-3';
        } else if (outputPost.Body.length < 40) {
            bodySize = ' fs-4';
        } else if (outputPost.Body.length < 50) {
            bodySize = ' fs-5';
        } else if (outputPost.Body.length < 200) {
            bodySize = ' fs-6';
        }
    }

    const renderQuillDelta = () => {
        const delta = outputPost.PostExtraData.BlogDeltaRtfFormat;
        if (!delta || !divRef.current) return null;

        let deltaObject;
        try {
            deltaObject = JSON.parse(delta);
            const quill = new Quill(divRef.current, { readOnly: true });
            quill.setContents(deltaObject.ops);
        } catch (error) {
            console.error("Failed to parse Quill Delta:", error);
        }
    };

    let title;
    if(outputPost.PostExtraData && outputPost.PostExtraData.Title) {
        title = outputPost.PostExtraData.Title;
    }

    let parsedBody = outputPost.Body;
    let renderContent;
    let subscriptionContent;
    let htmlContent;
    let markdownContent;

    // markdown rendering options
    const renderOptions = {
        components: {
            pre({ node, children, ...props }) {
                // Ensure children are handled correctly
                let lang = 'jsx';
                const codeString = React.Children.map(children, child => {
                    if (typeof child === 'string') {
                        return child;
                    } else if (React.isValidElement(child) && child.props.children) {
                        return child.props.children; // Extract children from valid React elements
                    }
                    return '';
                }).join(''); // Join all strings together
    
                if (codeString.includes('<?php') || codeString.includes('echo') || codeString.includes('$')) {
                    lang = 'php';
                } else if (codeString.includes('def') || codeString.includes('import') || codeString.includes('class')) {
                    lang = 'python';
                } else if (codeString.includes('class') && codeString.includes('public')) {
                    lang = 'java';
                } else if (codeString.includes('using') || codeString.includes('class')) {
                    lang = 'csharp';
                } else if (codeString.includes('def') || codeString.includes('class')) {
                    lang = 'ruby';
                } else if (codeString.includes('package') || codeString.includes('func')) {
                    lang = 'go';
                } else if (codeString.includes('import') && (codeString.includes('class') || codeString.includes('struct'))) {
                    lang = 'swift';
                } else if (codeString.includes('import') && codeString.includes('interface')) {
                    lang = 'typescript';
                } else if (codeString.includes('#!/bin/bash') || codeString.includes('echo') || codeString.includes('if')) {
                    lang = 'shell';
                } else if (codeString.includes('import') || codeString.includes('export') || codeString.includes('function') || codeString.includes('const') || codeString.includes('let')) {
                    lang = 'javascript';
                }

                return (
                    <SyntaxHighlighter style={materialDark} language={lang} PreTag="div" {...props}>
                        {codeString}
                    </SyntaxHighlighter>
                );
            },
            code({ inline, className, children, ...props }) {
                // Handle inline code snippets
                return (
                    <code className={className} {...props}>
                        {children}
                    </code>
                );
            }
        }
    };
    
    
    //if(preview && outputPost) { console.log('[posts.js] HERE ref compose.js RENDERING Type '+type,post); }////
    switch (type) {
        case 1:
            //if(preview && outputPost) { console.log('[posts.js] HERE ref compose.js plaintext: ',outputPost.Body); }
            //parsedBody = parseLinks(outputPost.Body, window.location.hostname);
            //if(preview && outputPost) { console.log('[posts.js] HERE ref compose.js plaintext parsed ',parsedBody); }
            //renderContent = (parsedBody);
            if(outputPost.Body === '') { 
                renderContent = null; 
            } else {
                renderContent = <FormatTextContent text={outputPost.Body} />;
            }
            break;
        case 2:
            parsedBody = outputPost.PostExtraData.bodyMarkdown; //parseLinks(post.PostExtraData.bodyMarkdown, window.location.hostname);
            //console.log("[SyntaxHighlighting] processing markdown: ",parsedBody);
            renderContent = <ReactMarkdown {...renderOptions}>{parsedBody}</ReactMarkdown>;
            //console.log("[SyntaxHighlighting] renderContent: ",renderContent);
            break;
        case 3:
            // Handle Delta rendering
            // Originally - renderContent = <div ref={divRef} onLoad={renderQuillDelta()} />;
            const turndown = new Turndown();
            const converter = new QuillDeltaToHtmlConverter(JSON.parse(post.PostExtraData.BlogDeltaRtfFormat), {});
            htmlContent = converter.convert();
            markdownContent = turndown.turndown(htmlContent);
            parsedBody = markdownContent;
            renderContent = <ReactMarkdown {...renderOptions}>{parsedBody}</ReactMarkdown>;
            break;
        case 4:
            // post.PostExtraData.isMarkdown: boolean, whether to treat as markdown formatting
            // post.PostExtraData.AccessGroupDerivedPublicKey: accessGroup
            // post.PostExtraData.SubscriptionAccessGroupKeyName: Key name
            // post.PostExtraData.SubscriptionAccessGroupOwnerPublicKey: Owner (poster)
            // post.PostExtraData.SubscriptionAccessGroupPublicKey: accessGroup
            // post.PostExtraData.SubscriptionEncryptedPostBody: the content (encrypted)

            //console.log("[Body Output] accessGroups:", accessGroups);

            if(decrypting) {
                subscriptionContent = false;
                renderContent = <div className='w-100 py-4 text-center'><Spinner /><br/><span className='text-muted'>...decrypting...</span></div>
            } else if(outputPost.PostExtraData.bodyMarkdown && decryptedContent) {
                // has been decoded
                //console.log("[posts.js] Thinks decryption was successful.");
                parsedBody = outputPost.PostExtraData.bodyMarkdown; //parseLinks(post.PostExtraData.bodyMarkdown, window.location.hostname);
                if(outputPost?.PostExtraData?.isMarkdown) {
                    renderContent = <ReactMarkdown {...renderOptions}>{parsedBody}</ReactMarkdown>;
                } else {
                    renderContent = nl2br(parsedBody);
                }
            } else {
                //console.log("[posts.js] decryption not successful.");
                parsedBody = outputPost.Body.trim();
                parsedBody = parsedBody.split("\n\n\n")[0];
                parsedBody = parseLinks(parsedBody, window.location.hostname);
                renderContent = nl2br(parsedBody);
                subscriptionContent = 
                <>
                    {outputPost?.PostExtraData?.PriceToUnlockUsdCents ? (
                        <>
                            <div className='text-body text-center d-flex flex-column align-items-center justify-content-center'>
                                <i className='bi bi-award-fill text-warning fs-3'></i>
                                <span>You must subscribe to <strong>{outputPost.ProfileEntryResponse && outputPost.ProfileEntryResponse.Username ? outputPost.ProfileEntryResponse.Username: `Author`}</strong> to view this post</span>
                                <span className='pt-3'><SubscriptionButton post={outputPost} /></span>
                            </div>
                        </>
                    ) : (
                        <div className='text-body text-center d-flex flex-column align-items-center justify-content-center'>
                            <i className='bi bi-lock-fill text-warning fs-3 '></i>
                            <span>You must be a member of <strong>'{outputPost?.PostExtraData?.SubscriptionAccessGroupKeyName}'</strong> to view this post</span>
                        </div>
                    )}
                    {outputPost?.PostExtraData?.abcdefghijkaba && (
                        <div className='text-center'>
                            <span className='mb-3 btn btn-sm btn-outline-secondary' onClick={toggleExtraData}>Show Extra Data</span>
                            {showExtraData && Object.keys(outputPost.PostExtraData).length > 0 ? (
                                <div id={`${outputPost.PostHashHex}_extradata`} className='mb-3 word-break text-truncate small'>
                                    <dl>
                                        {Object.entries(outputPost.PostExtraData).map(([key, value]) => (
                                            <React.Fragment key={key}>
                                                <dt>{key}</dt>
                                                <dd className='text-muted'>{value}</dd>
                                            </React.Fragment>
                                        ))}
                                    </dl>
                                </div>
                            ) : null }
                        </div>
                    )}
                </>;
            }           
            break;
        default:
            parsedBody = parseLinks(outputPost.Body, window.location.hostname);
            renderContent = (parsedBody);
            break;
    }

    
    if(!renderContent || renderContent === false) {
        return;
    }
    
    //if(preview && outputPost.PosterPublicKeyBase58Check === "BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD") { console.log('[posts.js] HERE ref compose.js RENDER:',renderContent); }
    return (
        <div className={`${hidden ? `bg-opacity-50 text-opacity-50` : ``}`}>
            {preview ? (
                <>
                    {title && (
                        <div className={`px-${padding} pt-${padding} postTitle text-body`}>
                            <h1>{title}</h1>
                        </div>
                    )}
                    {renderContent && (
                        <div
                            id={`preview${outputPost.PostHashHex}`}
                            className={`postPreview w-100 text-break p-${padding} ${bodySize}`}
                            style={{ zIndex: "100", opacity: hidden ? `0.75` : `1`, maxHeight: showFullContent ? 'none' : `${maxLength}px` }}
                        >
                            {renderContent}
                        </div>
                    )}
                </>
            ) : (
                <Link className='postBodyLink' to={`/posts/${outputPost.PostHashHex}`} onClick={onClickFunction}>
                    {title && (
                        <div className={`px-${padding} pt-${padding} postTitle text-body`}>
                            <h4>{title}</h4>
                        </div>
                    )}
                    {renderContent && (
                        <div
                            id={`preview${outputPost.PostHashHex}`}
                            className={`postPreview text-break p-${padding} ${bodySize}`}
                            style={{ zIndex: "100", maxWidth: "100%", opacity: hidden ? `0.75` : `1`, maxHeight: showFullContent ? 'none' : `${maxLength}px` }}
                        >
                            {renderContent}
                        </div>
                    )}
                </Link>
            )}
            {!showFullContent && contentHeightExceedsMaxLength && (
                <div className='text-center bg-opacity-10'>
                    <span onClick={toggleReadMore} className="action m-0 py-2 px-5 btn text-muted">
                        <i className="bi bi-chevron-down"></i> Read more...
                    </span>
                </div>
            )}
            {showFullContent && contentHeightExceedsMaxLength && outputPost.Body && outputPost.Body.length > maxLength && (
                <div className='text-center'>
                    <span onClick={toggleReadMore} className="action m-0 py-2 px-5 btn text-muted">
                        <i className="bi bi-chevron-up"></i> show less...
                    </span>
                </div>
            )}
            {subscriptionContent && (
                <div
                    className={`postPreview text-muted text-break p-${padding} ${bodySize} ${parsedBody ? `pt-0` : ``}`}
                >{subscriptionContent}</div>
            )}
        </div>
    );
    
}

export const PinnedPosts = ({ profile, currentUser, preferences, accessGroups }) => {
    const [formattedPosts, setFormattedPosts] = useState([]);
  
    useEffect(() => {
      const fetchPinnedPosts = async () => {
        try {
          const variables = {
            "condition": {
                "isHidden": false
            },
            "filter": {
                "and": [
                    {
                        "poster": {
                            "username": {
                                "equalToInsensitive": profile.Username,
                            }
                        }
                    },
                    {
                        "extraData": {
                            "contains": {
                                "IsPinned": "true",
                            }
                        }
                    },
                    {
                        "repostedPostHash": {
                            "isNull": true,
                        }
                    }
                ]
            },
            "first": 3,
            "orderBy": [
                "TIMESTAMP_DESC"
            ],
            "after": null
          };
  
          const postData = await getPosts(variables, currentUser, preferences);
  
          if (postData && postData.nodes) {
            const formattedData = await formatPosts(postData.nodes, currentUser);
            setFormattedPosts(formattedData);
          }
        } catch (error) {
          console.error('Error fetching pinned posts:', error);
          // Handle error if needed
        }
      };
  
      fetchPinnedPosts();
    }, [profile.Username, currentUser]);
  
    const formatPosts = async (posts) => {
      try {
        const formattedPosts = await Promise.all(posts.map(post => formatPost(post.PostFound, currentUser, 0, 'pinned')));
        return formattedPosts;
      } catch (error) {
        // console.error('Error formatting posts:', error);
      }
    };
  
    //console.log("[profiles.js] PinnedPosts objecT:", formattedPosts);
  
    return (
        <>
          {formattedPosts.map((formattedPost, index) => (
            <div key={index}>
              {formattedPost}
            </div>
          ))}
        </>
    );
  };
  
  /*
  export const remapCommentTree = (post) => {
    const output = { PostFound: {} };

    const findDeepestComment = (parentComment) => {
        if (!parentComment.Comments || parentComment.Comments.length === 0) {
            return parentComment;
        }
        return findDeepestComment(parentComment.Comments[parentComment.Comments.length - 1]);
    };

    const addComment = (parentComment, comment) => {
        if (parentComment.PostHashHex === comment.ParentStakeID) {
            if (!parentComment.Comments) {
                parentComment.Comments = [];
            }
            parentComment.Comments.push(comment);
        } else {
            if (parentComment.Comments) {
                for (let i = 0; i < parentComment.Comments.length; i++) {
                    addComment(parentComment.Comments[i], comment);
                }
            }
        }
    };

    if (post.PostFound.ParentPosts.length > 0) {
        post.PostFound.ParentPosts.forEach((parentPost, index) => {
            if (index === 0) {
                output.PostFound = parentPost;
            } else {
                addComment(output.PostFound, parentPost);
            }
        });
    }

    // Find the deepest comment and append the original post beneath it
    const deepestComment = findDeepestComment(output.PostFound);
    if (!deepestComment.Comments) {
        deepestComment.Comments = [];
    }
    deepestComment.Comments.push(post.PostFound);

    return output;
};*/

export const remapCommentTree = (post) => {
    let remappedPost = {};

    if (post?.ParentPosts?.length > 0) {
        // Initialize with the original post
        let currentPost = { ...post };

        // Iterate over ParentPosts in reverse order and nest them
        const parentPosts = post.ParentPosts;
        for (let i = parentPosts.length - 1; i >= 0; i--) {
            // Create a new object for the current parent post and nest the previous post as its comment
            currentPost = { ...parentPosts[i], Comments: [currentPost] };
        }

        // After the loop, currentPost will be the last parent post with all others nested
        remappedPost = currentPost;
    } else {
        // No parent posts, so just return the original post
        remappedPost = post;
    }

    return remappedPost;
};
